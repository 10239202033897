(function() {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .directive('selectAllOnCtrlA', selectAllOnCtrlA);


    // Add select-all-on-ctrl-a to a <select> tag to make ctrl+a select all options, needed for firefox.
    function selectAllOnCtrlA() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (element[0].tagName !== 'SELECT' || !element[0].multiple) {
                    return;
                }

                element.on('keydown', function(e) {
                    if ((e.ctrlKey || e.metaKey) && (e.key === 'a' || e.keyCode === 65)) {
                        if (e.target === this) {
                            e.preventDefault();
                            e.stopPropagation();

                            const collectionName = attrs.ngOptions.match(/in\s+(\w+)/)[1];
                            const collection = scope[collectionName];

                            ngModel.$setViewValue(collection);

                            Array.from(this.options)
                                .filter(option => option.value !== '-1')
                                .forEach(option => option.selected = true);

                            scope.$apply();
                        }
                    }
                });

                scope.$on('$destroy', function() {
                    element.off('keydown');
                });
            }
        };
    }
})();
