(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('unitsDetailsModalAdministrationController', unitsDetailsModalAdministrationController);

    unitsDetailsModalAdministrationController.$inject = [
        '$q',
        '$translate',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'unit',
        'currentFormType',
        'formTypes',
        'unitService',
        'unitGroups',
        'measurementTypes',
        'resources',
        'supplierService',
        'productCatalogService'
    ];

    function unitsDetailsModalAdministrationController(
        $q,
        $translate,
        $rootScope,
        $scope,
        $windowInstance,
        unit,
        currentFormType,
        formTypes,
        unitService,
        unitGroups,
        measurementTypes,
        resources,
        supplierService,
        productCatalogService
    ) {
        $scope.unit = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        $scope.unitGroupsOptions = {
            dataSource: new kendo.data.DataSource({
                data: unitGroups
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            optionLabel: $translate.instant('ADMIN_UNITS_SELECT_PRODUCT_GROUP'),
            select: unitGroupSelect,
        };

        $scope.suppliersOptions = {
            dataValueField: 'supplierId',
            dataTextField: 'name',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.suppliers)
                }
            }),
            optionLabel: ' ',
            filter: 'contains',
            change: supplierChange,
        };

        $scope.resourcesOptions = {
            dataSource: new kendo.data.DataSource({
                data: resources.sort((a, b) => a.resourceDescription > b.resourceDescription ? 1 : -1)
            }),
            dataValueField: 'resourceId',
            dataTextField: 'resourceDescription',
            optionLabel: $translate.instant('ADMIN_UNITS_SELECT_RESOURCE'),
            filter: 'contains',
            select: resourcesSelect,
        };

        $scope.supplierProductsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.supplierProducts)
                }
            }),
            template: (dataItem) => { return dataItem.productName + ' (' + dataItem.externalProductId + ')' },
            dataValueField: 'externalProductId',
            dataTextField: 'productName',
            optionLabel: $translate.instant('Select supplier product'),
            filter: 'contains',
            select: supplierProductSelect, 
        };
        
        $scope.measurementTypesOptions = {
            dataSource: new kendo.data.DataSource({
                data: measurementTypes
            }),
            dataValueField: 'measurementUnitTypeId',
            dataTextField: 'measurementUnitDescription',
            optionLabel: $translate.instant('ADMIN_UNITS_SELECT_MEASUREMENT_UNIT')
        };

        $scope.selectedUnitGroup = null;
        $scope.selectedSupplier = null;
        $scope.selectedMeasurementTypes = null;
        $scope.selectedSupplierProduct = null;
        $scope.selectedResources = null;
        $scope.suppliers = [];
        $scope.supplierProducts = [];

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitDetailsModalIndicator',
                destination: '.k-widget.k-window',
                overlay: true
            });

            if (currentFormType === formTypes.edit && unit) {
                $scope.unit = unit;
            }

            $scope.selectedUnitGroup = unitGroups.find(function (unitGroup) {
                return unitGroup.id == $scope.unit.unitGroupId;
            });

            $scope.selectedMeasurementTypes = measurementTypes.find(function (measurementType) {
                return measurementType.measurementUnitTypeId == $scope.unit.measurementUnitTypeId;
            });

            $scope.selectedResources = resources.find(function (resource) {
                return resource.resourceId == $scope.unit.resourceId;
            });

            $q.all({
                suppliers: supplierService.getSuppliers()
            }).then((result) => {
                $scope.suppliers = result.suppliers;

                if (currentFormType === formTypes.edit) {
                    $scope.selectedSupplier = result.suppliers.find(s => s.supplierId === unit.supplierId);
                }

                $scope.suppliersOptions.dataSource.read();

                $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
            })
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'unitDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                $scope.unit.unitGroupId = $scope.selectedUnitGroup ? $scope.selectedUnitGroup.id : null;
                $scope.unit.measurementUnitTypeId = $scope.selectedMeasurementTypes ? $scope.selectedMeasurementTypes.measurementUnitTypeId : null;
                $scope.unit.resourceId = $scope.selectedResources ? $scope.selectedResources.resourceId : null;
                $scope.unit.supplierId = $scope.selectedSupplier ? $scope.selectedSupplier.supplierId : null;

                switch (currentFormType) {
                    case formTypes.add:
                        $scope.unit.contractorId = $rootScope.authData.contractorId;

                        unitService.addUnit($scope.unit)
                            .then(unit => close($scope.unit))
                            .catch(() => close());
                        break;
                    case formTypes.edit:

                        unitService.updateUnit($scope.unit)
                            .then(unit => close($scope.unit))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function getUnitGroupSupplierTypes(selectedUnitGroup) {
            let typesList = [];
            if (selectedUnitGroup) {
                if (selectedUnitGroup.isMooringProduct) {
                    typesList.push("Mooring");
                }
                if (selectedUnitGroup.isNetProduct) {
                    typesList.push("Net");
                }
                if (selectedUnitGroup.isLiceSkirtProduct) {
                    typesList.push("LiceSkirt");
                }
                if (selectedUnitGroup.isRingProduct) {
                    typesList.push("Ring");
                }
            }

            return typesList;
        }

        function unitGroupSelect(e) {
            var selected = e.dataItem;
            var types = getUnitGroupSupplierTypes(selected);
            refreshSupplierList(types);
            refreshProductsList({});
            resetResource();
            resetMeasurementType();
            resetSupplier();
        }

        function supplierChange() {
            if ($scope.selectedSupplier && $scope.selectedSupplier.supplierId === '') {
                $scope.selectedSupplier = null;
            }

            refreshProductsList($scope.selectedResources);
        }

        function resourcesSelect(e) {
            refreshProductsList(e.dataItem);
        }

        function supplierProductSelect(e) {
            var product = e.dataItem;
            setSelectedSupplierProductData(product);
        }

        function resetMeasurementType() {
            $scope.selectedMeasurementTypes = null;
        }

        function resetResource() {
            $scope.selectedResources = null;
        }

        function resetSupplier() {
            $scope.selectedSupplier = null;
        }

        function refreshSupplierList(supplierTypes) {
            var filter = {
                IsActive: true,
                SupplierTypes: supplierTypes
            };

            supplierService.getSuppliersByFilter(filter)
                .then(result => {
                    $scope.suppliers = result;
                    $scope.suppliersOptions.dataSource.read();
                })
                .catch(error => console.log(error));
        }

        function refreshProductsList(selectedResourceCategory) {
            //update products list from product catalog based on selected unitGroup, supplier and category
            //catalog doesn't exist yet - have to mock one
            if (!($scope.selectedUnitGroup && $scope.selectedSupplier && selectedResourceCategory)) {
                return [];
            }

            var filter = {
                SupplierId: $scope.selectedSupplier.supplierId,
                ResourceId: selectedResourceCategory.resourceId
            };

            productCatalogService.getSupplierProductCatalogByFilter(filter).then(productsList => {
                $scope.supplierProducts = productsList.sort((a, b) => a.productName > b.productName ? 1 : -1);
                $scope.supplierProductsOptions.dataSource.read();
            });

            setSelectedSupplierProductData({});
        }

        function setSelectedSupplierProductData(selectedSupplierProduct) {
            
            if (selectedSupplierProduct.externalProductId !== '') {
                $scope.selectedSupplierProduct = selectedSupplierProduct;
                $scope.unit.externalUnitId = selectedSupplierProduct.externalProductId;
                $scope.unit.description = selectedSupplierProduct.productName;
            }
            else {
                $scope.setSelectedSupplierProduct = null;
                $scope.unit.externalUnitId = '';
                $scope.unit.description = '';
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
