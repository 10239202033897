(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentJobDetailsModalController', EquipmentJobDetailsModalController);

    EquipmentJobDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$windowInstance',
        '$translate',
        'formTypes',
        'logActionTypes',
        'equipmentJobDefinitionService',
        'logActionService',
        'userService',
        'departmentService',
        'dateUtility',
        'selectedEquipment',
        'currentFormType',
        'editedJobDefinition',
        'logActionStatus',
        'kendoUtility',
        '$rootScope',
        '$kWindow'
    ];

    function EquipmentJobDetailsModalController(
        $q,
        $scope,
        $windowInstance,
        $translate,
        formTypes,
        logActionTypes,
        equipmentJobDefinitionService,
        logActionService,
        userService,
        departmentService,
        dateUtility,
        selectedEquipment,
        currentFormType,
        editedJobDefinition,
        logActionStatus,
        kendoUtility,
        $rootScope,
        $kWindow
    ) {

        // MODEL
        $scope.jobDescription = '';
        $scope.jobLogAction;
        $scope.validFrom = new Date();
        $scope.validFrom.setHours(0,0,0,0);
        $scope.validTo;
        $scope.currentTime = new Date();
        $scope.currentTime.setHours(0,0,0,0);
        $scope.dueDateInDays;
        $scope.warnUserByEmail;
        $scope.assignToNotifiedUser = false;
        $scope.logEditableAfterDueDate = true;
        $scope.jobTypes = getJobTypes();
        $scope.jobDepartment;
        $scope.selectedJobType;

        // Periodic job fields
        $scope.periodicIntervalValue;
        $scope.periodicIntervalTypes = getPeriodicIntervalTypes();
        $scope.periodicIntervalType;
        $scope.periodicIntervalWarningTimeDays;
        $scope.showPeriodicIntervalOptions = false;

        // Counter job fields
        $scope.counterIntervalValue;
        $scope.counterIntervalWarningValue;
        $scope.counterIntervalStartValue;
        $scope.showCounterIntervalOptions = false;

        // Edit job fields
        $scope.lastGeneratedLogDate;
        $scope.showLastGeneratedLogDate = false;
        $scope.lastGeneratedLogCounterValue;
        $scope.showLastGeneratedLogCounterValue = false;

        $scope.isEdit = false;

        $scope.save = save;
        $scope.saveInProgress = false;
        $scope.closeModal = closeModal;
        $scope.formValidationResult = false;
        $scope.openEquipmentJobDefinitionGroupModal = openEquipmentJobDefinitionGroupModal;

        $scope.users = [];
        $scope.departments = [];
        $scope.logActions = [];
        $scope.equipmentJobDefinitionGroups = [];

        $scope.validatorOptions = {
            rules: {
                validateFromDateRange: function (input) {
                    if (input.is("[name=item-validFrom]")) {
                        if ($scope.validTo && $scope.validFrom) {
                            return $scope.validTo > $scope.validFrom;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                },

                validateToDateRange: function (input) {
                    if (input.is("[name=item-validTo]")) {
                        if ($scope.validTo && $scope.validFrom) {
                            return $scope.validFrom < $scope.validTo;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                },

                validateFromDateMin: function (input) {
                    if (input.is("[name=item-validFrom]")) {
                        if ($scope.isEdit){
                            return true;
                        }
                        else {
                            return $scope.validFrom >= $scope.currentTime;
                        }
                    } else {
                        return true;
                    }
                },

                validatePeriodicIntervalValue: function (input) {
                    if (input.is("[name=periodic-interval-value]") && getPeriodicValuesRequired()) {
                        return input.val();
                    } else {
                        return true;
                    }
                },


                validateCounterIntervalValue: function (input) {
                    if (input.is("[name=counter-interval-value]") && getCounterValuesRequired()) {
                        return input.val();
                    } else {
                        return true;
                    }
                },


                validateCounterIntervalWarningValue: function (input) {
                    if (input.is("[name=counter-interval-warning-value]") && getCounterValuesRequired()) {
                        return input.val();
                    } else {
                        return true;
                    }
                },

                validateCounterIntervalWarningValueMin: function (input) {
                    if (input.is("[name=counter-interval-warning-value]") && getCounterValuesRequired()) {
                        if ($scope.counterIntervalValue) {
                            return $scope.counterIntervalValue > $scope.counterIntervalWarningValue;
                        }
                    } else {
                        return true;
                    }
                }
            },
            messages: {
                validatePeriodicIntervalValue:          $translate.instant('EQUIPMENT_JOB_DETAILS_PERIODIC_INTERVAL_VALUE_REQUIRED'),
                validateCounterIntervalValue:           $translate.instant('EQUIPMENT_JOB_DETAILS_PERIODIC_INTERVAL_VALUE_REQUIRED'),
                validateCounterIntervalWarningValue:    $translate.instant('EQUIPMENT_JOB_DETAILS_NOTIFICATION_TIME_WARNING'),
                validateCounterIntervalWarningValueMin: $translate.instant('EQUIPMENT_JOB_DETAILS_NOTIFICATION_TIME_LESS'),
                validateFromDateRange:                  $translate.instant('EQUIPMENT_JOB_DETAILS_VALID_FROM_BEFORE'),
                validateToDateRange:                    $translate.instant('EQUIPMENT_JOB_DETAILS_VALID_TO_AFTER'),
                validateFromDateMin:                    $translate.instant('EQUIPMENT_JOB_DETAILS_VALID_FROM_LATE')
            }
        }

        $scope.jobDefinitionGroupOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.equipmentJobDefinitionGroups)
                }
            }),
            optionLabel: $translate.instant('EQUIPMENT_JOB_MODAL_SELECT_GROUP'),
            dataValueField: 'equipmentJobDefinitionGroupId',
            dataTextField: 'name',
            footerTemplate: getAddJobDefinitionGroupTemplate(),
        };

        $scope.operationLogLogActionsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.logActions)
            }}),
            optionLabel: $translate.instant('EQUIPMENT_JOB_DETAILS_ACTION_SELECT'),
            dataValueField: 'logActionId',
            dataTextField: 'description'
        };

        $scope.jobDepartmentOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.departments)
            }}),
            optionLabel: $translate.instant('EQUIPMENT_JOB_DETAILS_SELECT_DEPARTMENT'),
            dataValueField: 'departmentId',
            dataTextField: 'name'
        };

        $scope.emailNotificationOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.users)
            }}),
            optionLabel: $translate.instant('EQUIPMENT_JOB_MODAL_SELECTUSER'),
            dataValueField: 'userId',
            dataTextField: 'displayName'
        };

        $scope.periodicIntervalTypeOptions = {
            dataSource: {
                data: $scope.periodicIntervalTypes
            },
            dataValueField: 'PeriodicIntervalTypeValue',
            dataTextField: 'PeriodicIntervalTypeName',
        };

        $scope.setValidFromMinDate = function() {
            if (editedJobDefinition !== null && editedJobDefinition.CreatedOn !== null){
                return editedJobDefinition.CreatedOn
            }
            else {
                return new Date();
            }
        }

        $scope.validFromDateOptions = {
            min: $scope.setValidFromMinDate()
        };

        $scope.jobIntegerFieldOptions = {
            decimals: 0,
            min: 1,
            format: "n0",
            spinners: false
        }

        $scope.jobTypeOptions = {
            dataSource: {
                data: $scope.jobTypes
            },
            dataValueField: 'JobTypeValue',
            dataTextField: 'JobTypeName',
            change: jobTypeChanged,
        }

        function getPeriodicIntervalTypes() {
            return [
                { PeriodicIntervalTypeValue: 0, PeriodicIntervalTypeName: $translate.instant('EQUIPMENT_JOB_DETAILS_DAYS')   },
                { PeriodicIntervalTypeValue: 1, PeriodicIntervalTypeName: $translate.instant('EQUIPMENT_JOB_DETAILS_WEEKS')    },
                { PeriodicIntervalTypeValue: 2, PeriodicIntervalTypeName: $translate.instant('EQUIPMENT_JOB_DETAILS_MONTHS') }
            ];
        }

        function getJobTypes() {
            return [
                { JobTypeValue: 0, JobTypeName: $translate.instant('EQUIPMENT_JOB_DETAILS_PERIODIC') },
                { JobTypeValue: 1, JobTypeName: $translate.instant('EQUIPMENT_JOB_DETAILS_COUNTER')    },
                { JobTypeValue: 2, JobTypeName: $translate.instant('EQUIPMENT_JOB_DETAILS_SERVICE')   }
            ]
        }

        function jobTypeChanged(event) {
            setModalJobTypeState(event.sender.selectedIndex, true);
        }

        function setModalJobTypeState(jobTypeValue, applyScope) {
            switch (jobTypeValue) {
                case 0:
                    $scope.showPeriodicIntervalOptions = true;
                    $scope.showCounterIntervalOptions  = false;

                    break;
                case 1:
                    $scope.showPeriodicIntervalOptions = false;
                    $scope.showCounterIntervalOptions  = true;

                    break;
                case 2:
                    $scope.showPeriodicIntervalOptions = true;
                    $scope.showCounterIntervalOptions  = true;
                    break;
            }

            if (applyScope) {
                $scope.$apply();
            }
        }


        initModal();
        function initModal() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'jobDefinitionDetailsModalLoad',
                destination: '#new-equipment-job-form',
                overlay: true
            });

            $scope.isEdit = currentFormType === formTypes.edit;

            $('body').kendoTooltip({
                filter: '#operation-log-logaction_listbox > li.k-item',
                position: 'right',
                content: function(e){
                  var item = $("#operation-log-logaction").data('kendoDropDownList').dataItem($(e.target));
                  return item.description;
                },
            });

            var filter = {
                contractorId: $scope.authData.contractorId,
                LogActionTypes: ['vesselLog', 'operationAndVessel', 'workOrderLog'],
                status: 0
            };

            $q.all([
                userService.getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId]}),
                departmentService.getDepartmentsWebapi($scope.authData.contractorId),
                logActionService.getActiveLogActionsAsDropdownByFilter(filter),
                equipmentJobDefinitionService.getEquipmentJobDefinitionGroupsByEquipmentItemId(selectedEquipment.id)
            ]).then( ([users, departments, logActions, jobDefinitionGroups]) => {
                $scope.users        = _.sortBy(users, 'displayName');
                $scope.departments  = _.sortBy(departments, 'name');
                $scope.logActions   = logActions;
                $scope.equipmentJobDefinitionGroups = _.orderBy(jobDefinitionGroups, ['name'], ['asc']);

            }).finally( () => {
                $scope.emailNotificationOptions.dataSource.read();
                $scope.jobDepartmentOptions.dataSource.read();
                $scope.operationLogLogActionsOptions.dataSource.read();
                $scope.jobDefinitionGroupOptions.dataSource.read();

                if (selectedEquipment.description) {
                    $scope.selectedEquipmentDescription = selectedEquipment.description;
                }

                if (!$scope.isEdit) {
                    $scope.selectedJobType              = $scope.jobTypes[0];
                    $scope.periodicIntervalType         = $scope.periodicIntervalTypes[0];
                    $scope.jobDepartment                = $scope.departments.find(d => d.departmentId == selectedEquipment.relatedEntityId);
                }
                else {
                    $scope.showLastGeneratedLogCounterValue = editedJobDefinition.itemJobType >= 1;
                    $scope.showLastGeneratedLogDate         = editedJobDefinition.itemJobType == 0 || editedJobDefinition.itemJobType == 2;

                    $scope.selectedJobDefinitionGroup       = $scope.equipmentJobDefinitionGroups.find(g => g.equipmentJobDefinitionGroupId == editedJobDefinition.equipmentJobDefinitionGroupId);
                    $scope.jobDescription                   = editedJobDefinition.description;
                    $scope.validFrom                        = new Date(editedJobDefinition.validFrom);
                    $scope.validTo                          = editedJobDefinition.validTo ? new Date(editedJobDefinition.validTo) : null;
                    $scope.dueDateInDays                    = editedJobDefinition.dueDateDays;
                    $scope.logEditableAfterDueDate          = editedJobDefinition.operationLogEditableAfterDueDate;
                    $scope.selectedJobType                  = $scope.jobTypes.find( type => type.JobTypeValue == editedJobDefinition.itemJobType );
                    $scope.periodicIntervalType             = $scope.periodicIntervalTypes.find( type => type.PeriodicIntervalTypeValue == editedJobDefinition.periodicIntervalType );
                    $scope.periodicIntervalValue            = editedJobDefinition.periodicIntervalValue;
                    $scope.periodicIntervalWarningTimeDays  = editedJobDefinition.periodicIntervalWarningTimeDays;
                    $scope.counterIntervalValue             = editedJobDefinition.counterIntervalValue;
                    $scope.counterIntervalWarningValue      = editedJobDefinition.counterIntervalWarningValue;
                    $scope.lastGeneratedLogDate             = editedJobDefinition.lastGeneratedLogDate ? new Date(editedJobDefinition.lastGeneratedLogDate) : null;
                    $scope.lastGeneratedLogCounterValue     = editedJobDefinition.lastGeneratedLogCounterValue;
                    $scope.assignToNotifiedUser             = editedJobDefinition.assignToNotifiedUser;
                    $scope.warnUserByEmail                  = $scope.users.find(u => u.userId == editedJobDefinition.notifyEmailUserId);
                    $scope.jobLogAction                     = $scope.logActions.find(l => l.logActionId == editedJobDefinition.logActionId);
                    $scope.jobDepartment                    = $scope.departments.find(d => d.departmentId == editedJobDefinition.departmentId);
                    $scope.groupPriority                    = editedJobDefinition.groupPriority ? editedJobDefinition.groupPriority : null

                    $scope.counterIntervalStartValue        = editedJobDefinition.counterIntervalStartValue;

                    $scope.jobCreatedDate = new Date(editedJobDefinition.createdOn);
                    $scope.jobCreatedDate.setHours(0,0,0,0);


                }

                setModalJobTypeState($scope.selectedJobType.JobTypeValue, false);
                $rootScope.$broadcast('hideBusyIndicator', 'jobDefinitionDetailsModalLoad');
            });


        }

        $scope.onDateSelected = function(value) {
            var dateTime = value;
            var minDate = kendo.parseDate($scope.setValidFromMinDate());
            if(!dateTime || dateTime < minDate) {
                $scope.validFromWarning = true;
            }
            else {
                $scope.validFromWarning = false;
            }
        };



        function createJobDefinitionFromModalValues(isEdit) {

            let equipmentJobDefinition = {
                EquipmentJobDefinitionId:           isEdit ? editedJobDefinition.equipmentJobDefinitionId : undefined,
                ItemId:                             selectedEquipment.id,
                ContractorId:                       selectedEquipment.contractorId,
                DepartmentId:                       $scope.jobDepartment.departmentId,
                LogActionId:                        $scope.jobLogAction.logActionId,
                NotifyEmailUserId:                  $scope.warnUserByEmail ? $scope.warnUserByEmail.userId : undefined,
                AssignToNotifiedUser:               $scope.assignToNotifiedUser,
                CreatedById:                        isEdit ? editedJobDefinition.createdById : $scope.authData.userId,
                CreatedOn:                          isEdit ? editedJobDefinition.createdOn : dateUtility.toJson(new Date()),
                LastEditedById:                     $scope.authData.userId,
                LastEditedOn:                       dateUtility.toJson(new Date()),
                Description:                        $scope.jobDescription,
                ItemJobType:                        $scope.selectedJobType.JobTypeValue,
                ValidFrom:                          dateUtility.toJson(dateUtility.createUTCDate($scope.validFrom)),
                ValidTo:                            $scope.validTo ? dateUtility.toJson(dateUtility.createUTCDate($scope.validTo)) : undefined,
                DueDateDays:                        $scope.dueDateInDays,
                OperationLogEditableAfterDueDate:   $scope.logEditableAfterDueDate,
                LastGeneratedLogDate:               isEdit ? $scope.lastGeneratedLogDate ? dateUtility.toJson(dateUtility.createUTCDate($scope.lastGeneratedLogDate)) : undefined : undefined,
                LastGeneratedLogCounterValue:       isEdit ? $scope.lastGeneratedLogCounterValue : undefined,
                EquipmentJobDefinitionGroupId:      $scope.selectedJobDefinitionGroup ? $scope.selectedJobDefinitionGroup.equipmentJobDefinitionGroupId : null,
                GroupPriority:                      $scope.groupPriority ? $scope.groupPriority : null
            }

            if (getPeriodicValuesRequired()) {
                equipmentJobDefinition = {
                    ...equipmentJobDefinition,
                    ...{
                        PeriodicIntervalType:               $scope.periodicIntervalType.PeriodicIntervalTypeValue,
                        PeriodicIntervalValue:              $scope.periodicIntervalValue,
                        PeriodicIntervalWarningTimeDays:    $scope.periodicIntervalWarningTimeDays
                    }
                }
            }

            if (getCounterValuesRequired()) {
                equipmentJobDefinition = {
                    ...equipmentJobDefinition,
                    ...{
                        CounterIntervalValue:           $scope.counterIntervalValue,
                        CounterIntervalWarningValue:    $scope.counterIntervalWarningValue,
                        CounterIntervalStartValue:      $scope.counterIntervalStartValue
                    }
                }
            }


            return equipmentJobDefinition;
        }

        function save(event) {
            event.preventDefault();
            $scope.saveInProgress = true;

            if($scope.validator.validate() == true)
            {
                let equipmentJobDefinition = createJobDefinitionFromModalValues($scope.isEdit);
                if ($scope.isEdit)  {
                    equipmentJobDefinitionService.updateEquipmentJobDefinition(equipmentJobDefinition).then (function () {
                        $windowInstance.close();
                    });
                }
                else {
                    equipmentJobDefinitionService.addEquipmentJobDefinition(equipmentJobDefinition).then( function() {
                        $windowInstance.close();
                    });
                }
            } else {
                $scope.saveInProgress = false;
            }

        }

        function closeModal() {
            $windowInstance.close();
        }



        function getPeriodicValuesRequired() {
            return $scope.selectedJobType.JobTypeValue == 0 || $scope.selectedJobType.JobTypeValue == 2;
        }

        function getCounterValuesRequired() {
            return $scope.selectedJobType.JobTypeValue == 1 || $scope.selectedJobType.JobTypeValue == 2;
        }

        function getAddJobDefinitionGroupTemplate(){
            return ` <div class="dropdown-footer-template">
                <button class="k-button" onclick="addNewJobDefinitionGroup()">${$translate.instant('EQUIPMENT_JOB_MODAL_SELECT_ADD_NEW_GROUP_BTN')}</button>
            </div>`;
        }

        function openEquipmentJobDefinitionGroupModal(){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Equipment job group',
                    resizable: false,
                    minHeight: 150,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/equipments/equipment-job-group-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'EquipmentJobGroupDetailsModalController',
                resolve: {
                    equipmentJobDefinitionGroup: () => null,
                    selectedEquipmentId: () => selectedEquipment.id,
                    formType: () => formTypes.add,
                }
            });
            windowInstance.result.then(function(equipmentJobDefinitionGroup){
                $scope.equipmentJobDefinitionGroups.push(equipmentJobDefinitionGroup);
                $scope.jobDefinitionGroupOptions.dataSource.read();
                $scope.selectedJobDefinitionGroup = equipmentJobDefinitionGroup;
            });
        }
    }
})();
