(function() {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentsController', EquipmentsController);

    EquipmentsController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$stateParams',
        '$rootScope',
        '$kWindow',
        '$translate',
        'treeUtility',
        'itemtreeKeys',
        'itemTreeService',
        'authService',
        'formTypes',
        'mediaService'
    ];

    function EquipmentsController(
        $q,
        $scope,
        $state,
        $stateParams,
        $rootScope,
        $kWindow,
        $translate,
        treeUtility,
        itemtreeKeys,
        itemTreeService,
        authService,
        formTypes,
        mediaService
    ) {
        let expandedRowIds = [];
        $scope.authData = authService.getAuthData();
        $scope.categorySearchText = '';
        $scope.rootEquipmentCategory = null;
        $scope.categoryList = [];
        $scope.equipments = [];
        $scope.equipmentText = '';
        $scope.selectedCategory = null;
        $scope.selectedCategoryPath = [];
        $scope.searchItems = [];
        $scope.searchResultDisplay = false;

        $scope.addCategory = addCategory;
        $scope.editCategory = editCategory;
        $scope.cloneCategory = cloneCategory;
        $scope.addEquipment = addEquipment;
        $scope.addDocument = addDocument;
        $scope.editEquipment = editEquipment;
        $scope.editDocument = editDocument;
        $scope.deleteEquipment = deleteEquipment;
        $scope.categorySelected = categorySelected;
        $scope.clearSearch = clearSearch;
        $scope.currentDepartmentId = $stateParams.DepartmentId;
        $scope.filterEquipments = filterEquipments;
        $scope.selectCategory = selectCategory;
        $scope.selectEquipmentCategory = selectEquipmentCategory;
        $scope.showEvents = showEvents;
        $scope.showDocuments = showDocuments;
        $scope.showJobs = showJobs;
        $scope.moveEquipement = moveEquipement;
        $scope.showCriticalDocuments = showCriticalDocuments;
        $scope.weight = '';

        var categoryTab = $stateParams.CategoryTab;
        $scope.isDocumentTab = categoryTab == 'documents';

        dragElement( document.getElementById("separator"), "H" );

        $scope.extendSelectedCategory = $stateParams.CategoryId ? true : false;
        $scope.noSelectedVessel = false;

        $scope.tabStripOptions = {
            animation: false,
            scrollable: false
        };

        $scope.tabsVisibility = {
            equipments: true,
            documents: true
        };

        $scope.categorySearchOptions = {
            dataSource: new kendo.data.DataSource({
                data: []
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            filter: 'contains',
            placeholder: 'All...'
        };

        const itemTreeListOptions = langId => ({
            dataSource: new kendo.data.TreeListDataSource({
                data: [],
                schema: {
                    model: {
                        id: 'id',
                        expanded: false,
                        parentId: 'parentId',
                        fields: {
                            id: { type: 'string', editable: false, nullable: false },
                            description: { type: 'string' },
                            categoryName: { type: 'string' },
                        }
                    }
                }
            }),
            sortable: true,
            resizable: true,
            filterable: true,
            messages: {
                noRows: $translate.instant('G_NODATA', null, null, langId)
            },
            expand: function (e) {
                if (!expandedRowIds.includes(e.model.id)) {
                    expandedRowIds.push(e.model.id);
                }
            },
            collapse: function (e) {
                expandedRowIds = expandedRowIds.filter(x => x !== e.model.id);
            },
            dataBound: function () {
                const dataGrid = $('#equipment-tree-list').data('kendoTreeList');
                const dataItems = dataGrid.dataSource.view();

                // reopen expanded rows on grid refresh
                $.each(expandedRowIds, function (_, v) {
                    $.each(dataItems, function (_, y) {
                        if (v === y.id) {
                            dataGrid.expand(dataGrid.tbody.find("tr[data-uid='" + y.uid + "']"));
                        }
                    });
                });
            },
            columns: [
                {
                    field: 'categoryName',
                    title: $translate.instant('Kategori', null, null, langId),
                    hidden: true,
                    width: '10%',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.categoryName }}\'">{{dataItem.categoryName}}</span>'
                },
                {
                    field: 'description',
                    title: $translate.instant('G_DESCRIPTION', null, null, langId),
                    width: '16%',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.description }}\'"><span ng-if="#:!hasDocumentUrl#">#: description#</span><a href="#:documentUrl#" target="_blank"  ng-if="#:hasDocumentUrl#">#: description#</a>'
                },
                {
                    field: 'designationModelNumber',
                    title: $translate.instant('EQUIPMENT_GRID_MODEL_NUMBER', null, null, langId),
                    hidden: $scope.isDocumentTab,
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.designationModelNumber }}\'">{{dataItem.designationModelNumber}}</span>'
                },
                {
                    field: 'designationSerialNumber',
                    title: $translate.instant('EQUIPMENT_GRID_SERIAL_NUMBER', null, null, langId),
                    hidden: $scope.isDocumentTab,
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.designationSerialNumber }}\'">{{dataItem.designationSerialNumber}}</span>'
                },
                {
                    field: 'validityInterval.updateElapsedByParent',
                    title: $translate.instant('EQUIPMENT_GRID_UPDATE_ELAPSED', null, null, langId),
                    hidden: $scope.isDocumentTab,
                    template: '<span class="center-content"><input type="checkbox" #= validityInterval != null && validityInterval.updateElapsedByParent ? \'checked="checked"\' : ""# disabled="disabled" /></span>', width: 110
                },
                {
                    field: 'validityInterval.intervalElapsed',
                    title: $translate.instant('EQUIPMENT_GRID_INTERVAL_ELAPSED', null, null, langId),
                    hidden: $scope.isDocumentTab,
                    template: "#:validityInterval != null ? validityInterval.intervalElapsed + ' '+validityInterval.intervalTypeText : '' #"
                },
                {
                    field: 'validityInterval.validFrom',
                    title: $translate.instant('EQUIPMENT_GRID_INTERVAL_VALIDFROM', null, null, langId),
                    width: $scope.isDocumentTab ? 'auto' : 125,
                    hidden: !$scope.isDocumentTab,
                    template: "#:validityInterval != null && validityInterval.validFrom != null ? validityInterval.validFrom: '' #"
                },
                {
                    field: 'validityInterval.validTo',
                    title: $translate.instant('EQUIPMENT_GRID_INTERVAL_VALIDTO', null, null, langId),
                    width: $scope.isDocumentTab ? 'auto' : 125,
                    template: "#:validityInterval != null && validityInterval.validTo != null ? validityInterval.validTo: '' #"
                },
                {
                    field: 'validityInterval.warningTime',
                    title: $translate.instant('EQUIPMENT_GRID_WARNING_TIME', null, null, langId),
                    width: $scope.isDocumentTab ? 'auto' : 90,
                    hidden: $scope.isDocumentTab,
                    template: "#:validityInterval != null && validityInterval.warningTime !=null ? validityInterval.warningTime: '' #"
                },
                // The Weight field comes from the json inside the ItemDetails field - Weight property.
                {
                    field: 'weight',
                    title: $translate.instant('EQUIPMENT_GRID_WEIGHT', null, null, langId),
                    hidden: $scope.isDocumentTab,
                    width: 70
                },
                {
                    field: 'estimatedUsageTime',
                    title: $translate.instant('EQUIPMENT_GRID_ESTIMATED_USAGE_TIME', null, null, langId),
                    hidden: $scope.isDocumentTab,
                    width: $scope.isDocumentTab ? 'auto' : 125
                },
                {
                    field: 'comment',
                    title: $translate.instant('EQUIPMENT_GRID_COMMENT', null, null, langId),
                    hidden: !$scope.isDocumentTab,
                    width: $scope.isDocumentTab ? 'auto' : 125
                },
                {
                    field: 'lastChangedOn',
                    title: $translate.instant('EQUIPMENT_GRID_LAST_CHANGED_ON', null, null, langId),
                    width: $scope.isDocumentTab ? 'auto' : 125
                },
                {
                    field: 'equipmentList',
                    title: $translate.instant('EQUIPMENT_TAB_EQUIPMENTS', null, null, langId),
                    hidden: !$scope.isDocumentTab,
                    width: $scope.isDocumentTab ? 'auto' : 125
                },
                {
                    field: '',
                    title: '',
                    sortable: false,
                    filterable: false,
                    width: 225,
                    template: function(dataItem){
                        return `<div class="actions">
                            <i ng-show="${!$scope.isDocumentTab}" class="fa fa-file" aria-hidden="true" ng-click="showDocuments('${dataItem.id}')"></i>
                            <i ng-show="${!$scope.isDocumentTab}" class="fa fa-calendar" aria-hidden="true" ng-click="showEvents('${dataItem.id}', '${dataItem.description}')" title="Hendelser"></i>
                            <i ng-show="${!$scope.isDocumentTab}" class="fa fa-pencil-alt" aria-hidden="true" ng-click="editEquipment('${dataItem.id}')"></i>
                            <i ng-show="${$scope.isDocumentTab}" class="fa fa-pencil-alt" aria-hidden="true" ng-click="editDocument('${dataItem.id}')"></i>
                            <i ng-show="${!$scope.isDocumentTab}" class="fa fa-arrow-right" aria-hidden="true" ng-click="moveEquipement('${dataItem.id}')"></i>
                            <i ng-show="${!$scope.isDocumentTab}" class="fa fa-cog" aria-hidden="true" ng-click="showJobs('${dataItem.id}')"></i>
                            <i class="fa fa-trash" aria-hidden="true" ng-click="deleteEquipment('${dataItem.id}','${dataItem.children ? dataItem.children.length : 0}')"></i>
                        </div>`
                    }
                }
            ]
        });

        $scope.itemTreeListOptions = itemTreeListOptions($translate.use());

        $scope.equipmentSearchOptions = {
            placeholder: $translate.instant('EQUIPMENT_PLACEHOLDER_SEARCH_BOX'),
            noDataTemplate: $translate.instant('G_NODATA'),
            filter: 'contains',
            dataTextField: 'description',
            minLength: 1,
            highlightFirst: false,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function(e) {
                        e.success($scope.searchItems);
                    }
                }
            }),
            select: function(e) {
                $scope.filterEquipments(e);
            }
        };

        //Initialization code
        initEquipmentController($scope.currentDepartmentId);

        //init code
        function initEquipmentController(departmentId) {
            if (departmentId) {
                $scope.noSelectedVessel = false;

                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'equipmentInit',
                    destination: '#equipment-view'
                });

                $scope.searchResultDisplay = false;

                $q.all({
                    getItemCategoryByKey: itemTreeService.getItemCategoryByKey(itemtreeKeys.Equipment, $scope.authData.contractorId),
                    getItemsTreeByRelatedEntityId: itemTreeService.getItemsTreeByRelatedEntityId($rootScope.authData.contractorId, departmentId)
                })
                .then(function (result) {
                        $scope.rootEquipmentCategory = result.getItemCategoryByKey;
                        $scope.equipmentTreeData = result.getItemsTreeByRelatedEntityId;

                        var categoryKey = $scope.isDocumentTab ?  itemtreeKeys.Department: itemtreeKeys.Equipment;

                        //Get initial data
                        $q.all([
                            loadEquipments(),
                            updateItemCategoryMenu(categoryKey),
                            updateItemTreeList(result.getItemCategoryByKey.id, departmentId)
                        ]).then(function () {
                            //If params contain item description select it
                            if ($state.params.ItemDescription) {
                                var description = decodeURIComponent($state.params.ItemDescription);

                                $scope.equipmentText = description;
                                $scope.filterEquipments();
                            }
                            //If params contain category id, select it
                            else if ($state.params.CategoryId) {
                                var categoryToSelect = _.find($scope.categoryList, {
                                    id: $state.params.CategoryId.toLowerCase()
                                });

                                if (categoryToSelect) {
                                    setTimeout(function () {
                                        selectCategory(categoryToSelect);
                                    }, 250);
                                }
                            }

                            $rootScope.$broadcast('hideBusyIndicator', 'equipmentInit');
                        }, function () {
                            $rootScope.$broadcast('hideBusyIndicator', 'equipmentInit');
                        });
                    }, function () {
                        $rootScope.$broadcast('hideBusyIndicator', 'equipmentInit');
                    });
            } else {
                $scope.noSelectedVessel = true;
            }

            $scope.$watch("equipmentCategorytab", function () {
                switch (categoryTab) {
                    case "documents":
                        $scope.equipmentCategorytab.select(1);
                        break;
                    case "equipments":
                    default:
                        $scope.equipmentCategorytab.select(0);
                        break;
                }
            });

            $scope.$watch('showArchivedDocument', function () {
                if($scope.selectedCategory){
                    updateItemTreeList($scope.selectedCategory.id, $scope.currentDepartmentId);
                }
            });
        }

        $scope.setVisibleTab = function(index) {
            switch (index) {
                case 0:
                    $state.go('equipments', {
                        CategoryTab: 'equipments',
                    }, { notify: true });
                    break;
                case 1:
                    $state.go('equipments', {
                        CategoryTab: 'documents',
                    }, { notify: true });
                    break;
            }
        }

        $rootScope.$on('navbarItemSelected', onNavbarItemSelected);

        //$scope's functions
        function addCategory() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_CATEGORY_DETAILS_ADD_TITLE'),
                    resizable: true,
                    height: 200,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/equipments/category-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CategoryDetailsModalController',
                resolve: {
                    formType: function() {
                        return formTypes.add;
                    },
                    equipmentRootCategory: function() {
                        return $scope.rootEquipmentCategory;
                    },
                    itemCategories: function() {
                        return $scope.categoryList;
                    },
                    selectedCategory: function() {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function(result) {
                if (result) {
                    var categoryKey = $scope.isDocumentTab ?  itemtreeKeys.Department: itemtreeKeys.Equipment;
                    updateItemCategoryMenu(categoryKey);
                }
            });
        }

        function editCategory() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_CATEGORY_DETAILS_EDIT_TITLE'),
                    resizable: true,
                    height: 230,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/equipments/category-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CategoryDetailsModalController',
                resolve: {
                    formType: function() {
                        return formTypes.edit;
                    },
                    equipmentRootCategory: function() {
                        return $scope.rootEquipmentCategory;
                    },
                    itemCategories: function() {
                        return $scope.categoryList;
                    },
                    selectedCategory: function() {
                        return $scope.selectedCategory;
                    }
                }
            });

            windowInstance.result.then(function(result) {
                if (result) {
                    var categoryKey = $scope.isDocumentTab ?  itemtreeKeys.Department: itemtreeKeys.Equipment;
                    updateItemCategoryMenu(categoryKey);
                }
            });
        }

        //$scope's functions
        function cloneCategory() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_COPY_MODAL_TITLE'),
                    width: 450,
                    visible: false
                },
                templateUrl: 'app/equipments/category-clone-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CategoryCloneModalController',
                resolve: {}
            });

            windowInstance.result.then(function(result) {
                if (result) {
                    var categoryKey = $scope.isDocumentTab ?  itemtreeKeys.Department: itemtreeKeys.Equipment;
                    updateItemCategoryMenu(categoryKey);
                }
            });
        }

        function addEquipment() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_MODAL_TITLE_NEW'),
                    resizable: true,
                    minHeight: Math.min(250, $(window).height()-39),
                    minWidth: Math.min(300, $(window).width()),
                    width: Math.min(1000, $(window).width()),
                    visible: false
                },
                templateUrl: 'app/equipments/equipment-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'EquipmentDetailsModalController',
                resolve: {
                    formType: function() {
                        return formTypes.add;
                    },
                    departmentId: function() {
                        return $scope.currentDepartmentId;
                    },
                    selectedCategory: function() {
                        return $scope.selectedCategory;
                    },
                    itemCategories: function() {
                        return $scope.categoryList;
                    },
                    equipment: function() {
                        return $scope.rootEquipmentCategory;
                    }
                }
            });

            windowInstance.result.then(function(result) {
                if (result) {
                    if($scope.selectedCategory){
                        updateItemTreeList($scope.selectedCategory.id, $scope.currentDepartmentId);
                        loadEquipments();
                    } else {
                        showCriticalDocuments();
                    }
                }
            });
        }

        function addDocument() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('USER_DOCUMENTS_ADD_DOC'),
                    resizable: true,
                    minHeight: Math.min(250, $(window).height()-39),
                    width: 550,
                    visible: false
                },
                templateUrl: 'app/equipments/document-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DocumentDetailsModalController',
                resolve: {
                    formType: () => formTypes.add,
                    selectedCategory: () => $scope.selectedCategory,
                    itemCategories: () => $scope.categoryList,
                    document: () => $scope.rootEquipmentCategory,
                    equipmentTreeData: () => $scope.equipmentTreeData,
                    equipmentId: () => null
                }
            });

            windowInstance.result.then(function(result) {
                if (result) {
                    if($scope.selectedCategory){
                        updateItemTreeList($scope.selectedCategory.id, $scope.currentDepartmentId);
                        loadEquipments();
                    } else {
                        showCriticalDocuments();
                    }
                }
            });
        }

        function editEquipment(equipmentId) {
            itemTreeService.getItemById(equipmentId).then(function(equipment) {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        title: $translate.instant('EQUIPMENT_MODAL_TITLE_EDIT'),
                        minHeight: Math.min(250, $(window).height()-39),
                        minWidth: Math.min(300, $(window).width()),
                        width: Math.min(1000, $(window).width()),
                        visible: false
                    },
                    templateUrl: 'app/equipments/equipment-details-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'EquipmentDetailsModalController',
                    resolve: {
                        formType: function() {
                            return formTypes.edit;
                        },
                        departmentId: function() {
                            return $scope.currentDepartmentId;
                        },
                        selectedCategory: function() {
                            return $scope.selectedCategory || equipment.category;
                        },
                        itemCategories: function() {
                            return $scope.categoryList;
                        },
                        equipment: function() {
                            return equipment;
                        }
                    }
                });

                windowInstance.result.then(function(result) {
                    if (result) {
                        if($scope.selectedCategory){
                            updateItemTreeList($scope.selectedCategory.id, $scope.currentDepartmentId);
                        } else {
                            showCriticalDocuments();
                        }
                    }
                });
            });
        }

        function editDocument(equipmentId) {
            itemTreeService.getItemById(equipmentId).then(function(equipment) {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        title: $translate.instant('USER_DOCUMENT_EDIT_TITLE'),
                        minHeight: Math.min(250, $(window).height()-39),
                        width: 550,
                        visible: false
                    },
                    templateUrl: 'app/equipments/document-details-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'DocumentDetailsModalController',
                    resolve: {
                        formType: () => formTypes.edit,
                        selectedCategory: () => $scope.selectedCategory || equipment.category,
                        itemCategories: () => $scope.categoryList,
                        document: () => equipment,
                        equipmentTreeData: () => $scope.equipmentTreeData,
                        equipmentId: () => null
                    }
                });

                windowInstance.result.then(function(result) {
                    if (result) {
                        if($scope.selectedCategory){
                            updateItemTreeList($scope.selectedCategory.id, $scope.currentDepartmentId);
                        } else {
                            showCriticalDocuments();
                        }
                    }
                });
            });
        }

        function deleteEquipment(equipmentId, hasChildren) {
            var selectedEquipment = $scope.itemTreeListOptions.dataSource.data().find(e => e.id == equipmentId);
            var confirmContent = hasChildren ? $translate.instant('EQUIPMENT_DELETION_CONFIRM') : $translate.instant('G_AREYOUSURE');
            if(selectedEquipment && selectedEquipment.hasEquipmentJobDefinition){
                confirmContent += `<br>${$translate.instant('EQUIPMENT_DELETION_JOB_DEFINITION_PART')}`;
            }

            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: $translate.instant('G_CONFIRM'),
                    width: 450
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return confirmContent;
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (response) {
                if (response) {
                    itemTreeService.deleteItemWithChildren(equipmentId).then(function() {
                        if($scope.selectedCategory){
                            updateItemTreeList($scope.selectedCategory.id, $scope.currentDepartmentId);
                            loadEquipments();
                        } else {
                            showCriticalDocuments();
                        }
                    });
                }
            });
        }

        function moveEquipement(equipmentId) {
            itemTreeService.getItemById(equipmentId).then(function(equipment) {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        title: $translate.instant('EQUIPMENT_MOVE_MODAL_TITLE'),
                        resizable: true,
                        width: 450,
                        visible: false
                    },
                    templateUrl: 'app/equipments/equipment-move-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'EquipmentMoveModalController',
                    resolve: {
                        equipment: () => equipment
                    }
                });

                windowInstance.result.then(function(result) {
                    if (result) {
                        updateItemTreeList($scope.selectedCategory.id, $scope.currentDepartmentId);
                    }
                });
            });
        }

        function categorySelected(dataItem) {
            //hide categoryName column and set searchview false
            $scope.searchResultDisplay = false;
            showHideEquipmentTreeColumn("categoryName");

            if (dataItem) {
                $scope.selectedCategory = dataItem;

                //Update breadcrumb
                $scope.selectedCategoryPath = getCategoryParentList($scope.categoryList, dataItem);
                $state.transitionTo(
                    'equipments',
                    {
                        DepartmentId: $state.params.DepartmentId,
                        CategoryId: dataItem.id,
                        CategoryTab: categoryTab
                    },
                    { notify: false }
                );

                updateItemTreeList(dataItem.id, $scope.currentDepartmentId);
            }

            $scope.searchResultDisplay = false;
        }

        function clearSearch() {
            $scope.categorySearchText = '';
        }

        function filterEquipments(e) {
            var filterText = $scope.equipmentText.toLowerCase();
            if (filterText.length >= 1) {
                if (e) {
                    filterText = e.dataItem.description.toLowerCase();
                }

                var equipmentList = _.filter($scope.searchItems, function(item) {
                        if(item.description){
                        return item.description.toLowerCase().indexOf(filterText) !== -1;
                        } else {
                            return false;
                        }
                    });

                for (var i = 0; i < equipmentList.length; i++) {
                    equipmentList[i].parentId = null;
                }
                $scope.itemTreeListOptions.dataSource.data(equipmentList);

                $scope.searchResultDisplay = true;
            } else {
                if($scope.selectedCategory){
                    $scope.categorySelected($scope.selectedCategory);
                } else {
                    $scope.searchResultDisplay = false;
                    selectEquipmentCategory(null);
                }
            }

            showHideEquipmentTreeColumn("categoryName");
        }

        function selectEquipmentCategory() {
            //Unselect category by selecting a non-existing stuff
            $scope.categoryTree.select({});

            $scope.selectedCategory = null;
            $scope.selectedCategoryPath = [];
            $scope.itemTreeListOptions.dataSource.data([]);          
        }

        function selectCategory(category) {
            showHideEquipmentTreeColumn("categoryName");
            if ($scope.categoryTree) {

                if($scope.extendSelectedCategory){
                    var parentCategories = [];
                    getParents(category.id, parentCategories);
                    $scope.categoryTree.expandPath(parentCategories.reverse().map(x => x.id));
                }


                var categoryToSelect = $scope.categoryTree.findByText(category.name);

                if (categoryToSelect && categoryToSelect.length > 0) {
                    $state.transitionTo(
                        'equipments',
                        { DepartmentId: $state.params.DepartmentId, CategoryId: categoryToSelect.id },
                        { notify: false }
                    );

                    $scope.categoryTree.select(categoryToSelect);
                }
            }
        }

        const showHideEquipmentTreeColumn = (columnName) => {

            const dataTree = $('#equipment-tree-list').data('kendoTreeList');

            if ($scope.searchResultDisplay) {
                dataTree.showColumn(columnName);
            }
            else {
                dataTree.hideColumn(columnName);
            }
        }

        function getParents(categoryId, parentCategories)
        {
            var category = $scope.categoryList.find(c => c.id == categoryId);
            if(category && category.parentId){
                parentCategories.push(category);
                getParents(category.parentId, parentCategories)
            } else {
                parentCategories.push(category);
            }
        }

        function showEvents(equipmentId, equipmentDescription) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_EVENTS_TITLE'),
                    resizable: true,
                    visible: false,
                    width: '85vw',
                    height: '85vh'
                },
                templateUrl: 'app/equipments/equipment-events-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'EquipmentEventsModalController',
                resolve: {
                    departmentId: () => { return $scope.currentDepartmentId; },
                    relatedItemId: () => { return equipmentId; },
                    equipmentDescription:  () => { return equipmentDescription; }
                }
            });

            windowInstance.result.then(function(result) {
                console.log('Events window closed');
            });
        }

        function showDocuments(equipmentId) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('DOCUMENT_TITLE'),
                    resizable: true,
                    width: 1000,
                    visible: false
                },
                templateUrl: 'app/equipments/documents-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'documentsModalController',
                resolve: {
                    departmentId: () => $scope.currentDepartmentId,
                    equipmentId: () => equipmentId,
                    selectedCategory: () => $scope.selectedCategory
                }
            });
        }

        function showJobs(equipmentId) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EQUIPMENT_JOB_TITLE'),
                    resizable: true,
                    height: 585,
                    width: 1380,
                    visible: false
                },
                templateUrl: 'app/equipments/equipment-jobs-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'EquipmentJobsModalController',
                resolve: {
                    selectedEquipmentId: function() {
                        return equipmentId;
                    }
                }
            });

            windowInstance.result.then(function(result) {
                console.log('Events window closed');
            });
        }

        function onNavbarItemSelected(event, item) {
            if (item && item.type === 'Vessel' || item.type === 'Department' || item.type === 'Barge') {
                $scope.currentDepartmentId = item.departmentId;
                $state.transitionTo('equipments', { DepartmentId: item.departmentId }, { notify: false });

                initEquipmentController(item.departmentId);
            }
        }

        //Helper functions
        function updateItemCategoryMenu(categoryKey) {
            var deferred = $q.defer();

            var relatedEntityId = (categoryKey == itemtreeKeys.Equipment ? $scope.currentDepartmentId : null);

            itemTreeService
                .getItemCategoryTreeByKey(
                    categoryKey,
                    $scope.authData.contractorId,
                    relatedEntityId
                )
                .then(
                    function(categoryTree) {
                        //Flatten tree
                        var rootNodeId = categoryTree.id;
                        var treeNodeList = treeUtility.convertTreeToList(categoryTree, 'children', 'id');

                        if(categoryKey != itemtreeKeys.Department){
                            //Remove top node
                            if (treeNodeList[0].id === rootNodeId) {
                                treeNodeList.splice(0, 1);
                            }

                            //Remove reference to top node
                            for (var i = 0; i < treeNodeList.length; ++i) {
                                var node = treeNodeList[i];

                                if (node.parentId === rootNodeId) {
                                    node.parentId = null;
                                }
                            }
                        } else {
                            // remove root category parent reference to top node
                            treeNodeList[0].parentId = null;
                        }

                        //Update the autocomplete
                        $scope.categoryList = treeNodeList;

                        var itemCategoryForest = convertArrayToForest(treeNodeList, 'id', 'parentId', 'items');
                        $scope.categoryTreeData = new kendo.data.HierarchicalDataSource({
                            data: itemCategoryForest
                        });
                        deferred.resolve();
                    },
                    function() {
                        deferred.reject();
                    }
                );

            return deferred.promise;
        }

        function updateItemTreeList(itemCategoryId, departmentId) {
            var deferred = $q.defer();

            itemTreeService.getItemForestByCategoryId(itemCategoryId, $scope.authData.contractorId, departmentId).then(
                function(items) {

                    if(!$scope.showArchivedDocument){
                        items = items.filter(x => x.status !=  1);
                    }

                    for (var i = 0; i < items.length; ++i) {
                        extendEquipmentItem(items[i]);
                    }

                    //Order items by name
                    items = _.sortBy(items, ['description']);

                    $scope.equipments = items;
                    $scope.itemTreeListOptions.dataSource.data(items);

                    deferred.resolve();
                },
                function() {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function showCriticalDocuments(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'equipmentInit',
                destination: '#equipment-view'
            });

            $state.transitionTo(
                'equipments',
                { DepartmentId: $state.params.DepartmentId},
                { notify: false }
            );
            $scope.selectedCategory = null;
            $scope.categoryTree.select({});

            itemTreeService.loadCriticalDocuments($scope.currentDepartmentId).then(function(items){

                for (var i = 0; i < items.length; ++i) {
                    extendEquipmentItem(items[i]);
                    items[i].parentId = null;
                }

                $scope.equipments = items;
                $scope.itemTreeListOptions.dataSource.data(items);
            })
            .finally(function(){
                $rootScope.$broadcast('hideBusyIndicator', 'equipmentInit');
            });
        }

        function loadEquipments() {
            var deferred = $q.defer();

            if ($scope.authData.contractorId && $scope.currentDepartmentId) {
                var categoryKey = $scope.isDocumentTab ?  itemtreeKeys.Department: itemtreeKeys.Equipment;
                itemTreeService
                    .getEquipmentsByRelatedEntityId($scope.authData.contractorId, $scope.currentDepartmentId, categoryKey)
                    .then(
                        function(equipments) {
                            for (var i = 0; i < equipments.length; ++i) {
                                extendEquipmentItem(equipments[i]);
                            }

                            $scope.searchItems = _.sortBy(equipments, 'description');
                            $scope.equipmentSearchOptions.dataSource.read();

                            deferred.resolve();
                        },
                        function() {
                            deferred.reject();
                        }
                    );
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }

        $scope.archivedSwitchChanged = function(){

        }

        function extendEquipmentItem(item) {

            item.designationModelNumber = item.designation ? item.designation.modelNumber : '';
            item.designationSerialNumber = item.designation ? item.designation.serialNumber : '';

            //Add document link url to items
            if (item.files && item.files.length > 0) {
                item.files.sort((d1,d2) => { return new Date(d2.createdOn) - new Date(d1.createdOn) });
                var documentUrl = mediaService.createMediaUrl(item.files[0].mediaId);
                item.hasDocumentUrl = true;
                item.documentUrl = documentUrl;
            } else if (item.url) {
                item.hasDocumentUrl = true;
                item.documentUrl = item.url;
            } else {
                item.documentUrl = '';
                item.hasDocumentUrl = false;
            }

            item.equipmentList = item.equipments ? item.equipments.map(x => x.description).join(', ') : '';

            //weight property from itemdetails
            item.weight = getWeightPropertyFromItemDetails(item.itemDetails);
        }

        function getCategoryParentList(categoryList, category) {
            var maxNodeSearch = 100,
                categoryParentList = [],
                parent = _.find(categoryList, { id: category.parentId });

            var i = 0;
            while (parent && i < maxNodeSearch) {
                categoryParentList.push(parent);
                parent = _.find(categoryList, { id: parent.parentId });
                ++i;
            }

            return _.reverse(categoryParentList);
        }

        function getWeightPropertyFromItemDetails(itemDetails) {
            if (itemDetails) {
                var detailObject = JSON.parse(itemDetails);
                for (var prop in detailObject) {
                    if (prop.toLowerCase() === "weight")
                        return detailObject[prop];
                }
            }
            return '';
        }

        function convertArrayToForest(array, idProp, parentIdProp, childrenProp) {
            var topNodes = [];

            //Get top nodes
            for (var i = 0; i < array.length; ++i) {
                if (array[i][parentIdProp] === null) {
                    topNodes.push(array[i]);
                }
            }

            //Second level -> convert to recursive code
            for (var k = 0; k < array.length; ++k) {
                var childNode = array[k];

                if (childNode[parentIdProp] !== null) {
                    //Find parent
                    for (var j = 0; j < array.length; ++j) {
                        if (array[j][idProp] === childNode[parentIdProp]) {
                            if (array[j][childrenProp] === undefined) {
                                array[j][childrenProp] = [];
                            }

                            array[j][childrenProp].push(childNode);
                        }
                    }
                }
            }

            return topNodes;
        }

        // A function is used for dragging and moving
        function dragElement(element, direction)
        {
            var   md;
            const first  = document.getElementById("leftMenu");
            const second = document.getElementById("equipmentTreeContainer");

            element.onmousedown = onMouseDown;

            function onMouseDown(e)
            {
                md = {e,
                    offsetLeft:  element.offsetLeft,
                    offsetTop:   element.offsetTop,
                    firstWidth:  first.offsetWidth,
                    secondWidth: second.offsetWidth
                    };

                document.onmousemove = onMouseMove;
                document.onmouseup = () => {
                    document.onmousemove = document.onmouseup = null;
                }
            }

            function onMouseMove(e)
            {
                var delta = {x: e.clientX - md.e.clientX,
                            y: e.clientY - md.e.clientY};

                if (direction === "H")
                {
                    delta.x = Math.min(Math.max(delta.x, -md.firstWidth),
                            md.secondWidth);

                    var cursorPositonOnScreen = calculateItemWidthInPercent(e.clientX);
                    if(cursorPositonOnScreen > 10 && cursorPositonOnScreen < 70){
                        element.style.left = md.offsetLeft + delta.x + "px";
                        first.style.width = (md.firstWidth + delta.x) + "px";
                        second.style.width = (md.secondWidth - delta.x) + "px";
                    }
                }
            }
        }

        function calculateItemWidthInPercent(itemWidth) {
            // Get the screen width
            var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            // Calculate the percentage
            var percent = (itemWidth / screenWidth) * 100;

            return percent;
        }
    }
})();
