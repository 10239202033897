(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('supplierService', supplierService);

    supplierService.$inject = ['$q', '$http', 'serviceUrls'];

    function supplierService($q, $http, serviceUrls) {
        const suppliersWebApiUrl = `${serviceUrls.webApiBaseUrl}/supplier`;

        return {
            getSuppliers: getSuppliers,
            getBargeSuppliers: getBargeSuppliers,
            getSuppliersByFilter: getSuppliersByFilter
        };

        function getSuppliers() {
            var deferred = $q.defer();
            var requestUrl = `${suppliersWebApiUrl}`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function getBargeSuppliers() {
            var deferred = $q.defer();
            var requestUrl = `${suppliersWebApiUrl}/bargeSuppliers`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function getSuppliersByFilter(filter) {
            var deferred = $q.defer();
            var requestUrl = `${suppliersWebApiUrl}/byFilter`;
            $http
                .post(requestUrl, filter)
                .then(response => {
                    response && response.data && deferred.resolve(response.data);
                    deferred.reject();
                })
                .catch(error => deferred.reject(error));
            return deferred.promise;
        }
    }
})();
