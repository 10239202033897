(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('AdministrationController', administrationController);

    administrationController.$inject = ['$scope', '$state', 'authService'];

    function administrationController($scope, $state, authService) {
        $scope.tabStripOptions = {
            animation: false,
            select: function (e) {
                switch (e.item.id) {
                    case 'logActions-tab':
                        $state.go('administration.logActions');
                        break;
                    case 'vessels-tab':
                        $state.go('administration.vessels');
                        break;
                    case 'operation-types-tab':
                        $state.go('administration.operationTypes');
                        break;
                    case 'role-administration-tab':
                        $state.go('administration.role');
                        break;
                    case 'day-counter-administration-tab':
                        $state.go('administration.dayCounter');
                        break;
                    case 'customers-administration-tab':
                        $state.go('administration.customers');
                        break;
                    case 'units-administration-tab':
                        $state.go('administration.units');
                        break;
                    case 'persons-administration-tab':
                        $state.go('administration.persons');
                        break;
                    case 'landbases-administration-tab':
                        $state.go('administration.landbases');
                        break;
                    case 'document-types-administration-tab':
                        $state.go('administration.documentTypes');
                        break;
                    case 'lists-administration-tab':
                        $state.go('administration.lists');
                        break;
                    case 'order-process-administration-tab':
                        $state.go('administration.orderProcess');
                        break;
                    case 'logaction-pair-administration-tab':
                        $state.go('administration.logActionPairs');
                        break;
                    case 'job-title-administration-tab':
                        $state.go('administration.jobTitle');
                        break;
                    case 'contractor-administration-tab':
                        $state.go('administration.contractor');
                        break;
                    case 'tags-administration-tab':
                        $state.go('administration.tags');
                        break;
                    case 'location-sharing-administration-tab':
                        $state.go('administration.locationSharing');
                        break;
                    default:
                        break;
                }
            }
        };

        $scope.tabsVisibility = {
            logActions: true,
            vessels: true,
            operationTypes: true,
            roleAdministration: true,
            dayCounterAdministration: authService.hasPermission('UserAdministrationView'),
            customers: true,
            units: true,
            persons: true,
            landbases: true,
            documentTypes: true,
            lists: true,
            orderProcess: true,
            logActionPairs : true,
            jobTitle : true,
            contractor: $scope.authData.isSuperuser,
            tags : authService.hasPermission('TagAdmin'),
            locationSharing: true
        };
        $scope.visibleTabIndex = 0;
        $scope.setVisibleTab = setVisibleTab;

        initController();

        function initController() {
            $scope.$watch('administrationtab', function () {
                switch ($state.current.name) {
                    case 'administration.logActions':
                        $scope.administrationtab.select(0);
                        $scope.visibleTabIndex = 0;
                        break;
                    case 'administration.vessels':
                        $scope.administrationtab.select(1);
                        $scope.visibleTabIndex = 1;
                        break;
                    case 'administration.operationTypes':
                        $scope.administrationtab.select(2);
                        $scope.visibleTabIndex = 2;
                        break;
                    case 'administration.role':
                        $scope.administrationtab.select(3);
                        $scope.visibleTabIndex = 3;
                        break;
                    case 'administration.dayCounter':
                        $scope.administrationtab.select(4);
                        $scope.visibleTabIndex = 4;
                        break;
                    case 'administration.customers':
                        $scope.administrationtab.select(5);
                        $scope.visibleTabIndex = 5;
                        break;
                    case 'administration.units':
                        $scope.administrationtab.select(6);
                        $scope.visibleTabIndex = 6;
                        break;
                    case 'administration.persons':
                        $scope.administrationtab.select(8);
                        $scope.visibleTabIndex = 8;
                        break;
                    case 'administration.landbases':
                        $scope.administrationtab.select(9);
                        $scope.visibleTabIndex = 9;
                        break;
                    case 'administration.documentTypes':
                        $scope.administrationtab.select(10);
                        $scope.visibleTabIndex = 10;
                        break;
                    case 'administration.lists':
                        $scope.administrationtab.select(11);
                        $scope.visibleTabIndex = 11;
                        break;
                    case 'administration.orderProcess':
                        $scope.administrationtab.select(12);
                        $scope.visibleTabIndex = 12;
                        break;
                    case 'administration.logActionPairs':
                        $scope.administrationtab.select(13);
                        $scope.visibleTabIndex = 13;
                        break;
                    case 'administration.jobTitle':
                        $scope.administrationtab.select(14);
                        $scope.visibleTabIndex = 14;
                        break;
                    case 'administration.contractor':
                        $scope.administrationtab.select(15);
                        $scope.visibleTabIndex = 15;
                        break;
                    case 'administration.tags':
                        $scope.administrationtab.select(16);
                        $scope.visibleTabIndex = 16;
                        break;
                    case 'administration.locationSharing':
                        $scope.administrationtab.select(17);
                        $scope.visibleTabIndex = 17;
                        break;
                    default:
                        $scope.administrationtab.select(0);
                        $scope.visibleTabIndex = 0;
                        break;
                }
            });
        }

        function setVisibleTab(index) {
            $scope.visibleTabIndex = index;
        }
    }
})();
