//will eventually connect to the new product catalog module and get product catalog data from it.
//for now, it returns nothing because no api service or endpoint exists yet.
class mockedProduct {
    constructor(externalProductId, productName, supplierId, resourceId) {
        this.productCatalogId = uuidv4();
        this.externalProductId = externalProductId;
        this.productName = productName;
        this.supplierId = supplierId;
        this.resourceId = resourceId;
    }
};

(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('productCatalogService', productCatalogService);

    productCatalogService.$inject = [
        '$q',
        '$http',
        'serviceUrls',
    ];

    function productCatalogService(
        $q,
        $http,
        serviceUrls,
    ) {
        const productCatalogApiServiceUrl = ''; // `${serviceUrls.webApiBaseUrl}/productCatalog`;

        return {
            getSupplierProductCatalogByFilter,
        };

        

        function getSupplierProductCatalogByFilter(filter) {
            const deferred = $q.defer();
            //const url = `${productCatalogApiServiceUrl}`;

            //$http.post(url, filter)
            //    .then(response => {
            //        response && response.data && deferred.resolve(response.data);
            //        deferred.reject();
            //    })
            //    .catch(error => deferred.reject(error));

            // NB! replace with actual data when implemented, and remove class above and function below
            // for testing, fill the product catalog with data from the createMockedProductsCatalog function
            // var supplierProductCatalog = createMockedProductsCatalog();
            var supplierProductCatalog = [];

            supplierProductCatalog = supplierProductCatalog.filter(x => x.supplierId == filter.SupplierId && x.resourceId == filter.ResourceId);

            deferred.resolve(supplierProductCatalog);

            return deferred.promise;
        }

        function createMockedProductsCatalog() {
            const supplierForMock = 'e6ca0d30-9b15-4151-8f5e-cd041ed33c51'; //Scale Aquaculture
            const shackleForMock = '0183b52c-5314-475c-8bc7-e06576e19b38'; //Sjakkel - resource
            const thimbleForMock = 'fc41a2ca-fb89-46b9-a7dc-158c827adc07' //Kause - resource

            let mockedProductCatalog = [];
            mockedProductCatalog.push(new mockedProduct("300393", "Sjakkel MBL 28T forankring", supplierForMock, shackleForMock));
            mockedProductCatalog.push(new mockedProduct("300394", "Sjakkel MBL 40T forankring", supplierForMock, shackleForMock));
            mockedProductCatalog.push(new mockedProduct("300399", "Sjakkel MBL 60T forankring", supplierForMock, shackleForMock));
            mockedProductCatalog.push(new mockedProduct("300402", "Sjakkel MBL 90T forankring", supplierForMock, shackleForMock));
            mockedProductCatalog.push(new mockedProduct("301813", "Sjakkel MBL 110T forankring", supplierForMock, shackleForMock));
            mockedProductCatalog.push(new mockedProduct("301107", "Sjakkel MBL 150T forankring", supplierForMock, shackleForMock));
            mockedProductCatalog.push(new mockedProduct("301508", "Trossekause 40mm K3", supplierForMock, thimbleForMock));
            mockedProductCatalog.push(new mockedProduct("301375", "Trossekause 48mm K3", supplierForMock, thimbleForMock));
            mockedProductCatalog.push(new mockedProduct("301066", "Trossekause 56mm K3", supplierForMock, thimbleForMock));
            mockedProductCatalog.push(new mockedProduct("301116", "Trossekause 64mm K3", supplierForMock, thimbleForMock));
            mockedProductCatalog.push(new mockedProduct("301149", "Trossekause 72mm K3", supplierForMock, thimbleForMock));
            mockedProductCatalog.push(new mockedProduct("301161", "Trossekause 80mm K3", supplierForMock, thimbleForMock));
            mockedProductCatalog.push(new mockedProduct("301285", "Trossekause 90mm K3", supplierForMock, thimbleForMock));

            return mockedProductCatalog;
        }
    }
})();
