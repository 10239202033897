(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('NavbarController', NavbarController);

    NavbarController.$inject = [
        '$q',
        '$window',
        '$scope',
        '$rootScope',
        '$state',
        '$kWindow',
        '$translate',
        'setAttribute',
        'focus',
        'formTypes',
        'authService',
        'cacheService',
        'contractorService',
        'kendoUtility',
        'locationService',
        'departmentService',
        'workOrderDetailsModalService',
        'userService',
        'caseDetailsModalService',
        'noUser',
        'workOrderStatuses',
        'serviceUrls',
        'DepartmentStatus',
        'confirmDialogService'
    ];

    function NavbarController(
        $q,
        $window,
        $scope,
        $rootScope,
        $state,
        $kWindow,
        $translate,
        setAttribute,
        focus,
        formTypes,
        authService,
        cacheService,
        contractorService,
        kendoUtility,
        locationService,
        departmentService,
        workOrderDetailsModalService,
        userService,
        caseDetailsModalService,
        noUser,
        workOrderStatuses,
        serviceUrls,
        DepartmentStatus,
        confirmDialogService
    ) {
        $scope.isAuth = $scope.authData.isAuth;
        $scope.composeDisplayName = composeDisplayName;
        $scope.departmentName = '';
        $scope.filteredSearchItems = [];
        $scope.hasDepartment = false;
        $scope.login = login;
        $scope.logout = logout;
        $scope.navigateToEquipments = navigateToEquipments;
        $scope.navigateToVesselDashboard = navigateToVesselDashboard;
        $scope.navigateToCustomers = navigateToCustomers;
        $scope.openNewLogModal = openNewLogModal;
        $scope.openNewWorkOrderModal = openNewWorkOrderModal;
        $scope.openNewCaseModal = openNewCaseModal;
        $scope.openOperationLogs = openOperationLogs;
        $scope.pressedSearch = pressedSearch;
        $scope.search = search;
        $scope.changePassword = changePassword;
        $scope.changeContractorDepartment = changeContractorDepartment;
        $scope.navigateToProductRegister = navigateToProductRegister;

        $scope.searchBoxText = '';
        $scope.searchFilter = searchFilter;
        $scope.searchFilterType = 'All';
        $scope.searchItems = [];
        $scope.toMapView = toMapView;
        $scope.allDepartments = [];

        $scope.contractorDepartmentVisible = $rootScope.authData.isSuperuser || authService.hasRole('ContractorAdministrator');
        $scope.administrationTabVisible = $rootScope.authData.isSuperuser || authService.hasPermission('AdministrationView');
        $scope.systemAdminTabVisible = $rootScope.authData.isSuperuser;

        const searchOptions = (langId, placeholderText) => ({
            placeholder: placeholderText,
            noDataTemplate: $translate.instant('G_NODATA', null, null, langId),
            filter: 'contains',
            dataTextField: 'displayName',

            highlightFirst: true,
            dataSource: new kendo.data.DataSource({
                data: $scope.filteredSearchItems
            }),
            select: function (e) {
                $scope.search(this, e);
                $("#searchToggleContainer").collapse('hide');

                if (e.dataItem.departmentId) {
                    $scope.department = $scope.allDepartments.find(x => x.departmentId === e.dataItem.departmentId)
                }
            },
            template:
                '<div class="search-item-type" ><span class="k-state-default" > #: data.displayName #</span>' +
                '#if(data.type===\'Location\') {#<i class="fa fa-map-marker search-item-type" aria-hidden="true"  ></i>#}#' +
                '#if(data.type===\'Vessel\') {#<i class="fa fa-ship  search-item-type" aria-hidden="true"  ></i>#}#' +
                '#if(data.type===\'Department\') {#<i class="fa fa-users search-item-type" aria-hidden="true"  ></i>#}#' +
                '#if(data.type===\'Barge\') {#<i class="fab fa-simplybuilt search-item-type" aria-hidden="true"  ></i>#}#' +
                '</div>'
        });

        $translate.onReady().then(function(){
            $scope.searchOptions = searchOptions($translate.use(), getPlaceholderName($translate.use(), $scope.searchFilterType));
        });

        $scope.canEditServiceForms = function(){
            return true || $rootScope.authData.isSuperUser;
        }
        $scope.$on('langChanged', (_, langId) => {
            let placeholderName = getPlaceholderName(langId, $scope.searchFilterType);
            $scope.searchOptions = searchOptions(langId, placeholderName);

            const autocompleteWidget = kendoUtility.createWidget('kendoAutoComplete', $scope.searchOptions);
            kendoUtility.changeLangOfWidget(langId, [() => autocompleteWidget('#main-search-box')]);
        });

        initController();

        function initController() {
            //Wire in watching events
            $rootScope.$on('$stateChangeSuccess', onStateChanged);

            //Initialize search box if not in visitor mode
            if ($scope.authData.isAuth) {
                if ($scope.authData.activeDepartment) {
                    $scope.hasDepartment = true;
                    $scope.departmentName = $scope.authData.activeDepartment.name;
                } else {
                    $scope.hasDepartment = false;
                    $scope.departmentName = '';
                }

                var requests = {
                    getAvailableLocationsByUserId: locationService.getAvailableLocationsByUserId($scope.authData.userId),
                    getContractors: contractorService.getContractors(),
                    getVesselLocations: departmentService.getVesselLocations(authService.getAuthData.contractorId, false),
                    getDepartmentsWebapi: departmentService.getDepartmentsWebapi($scope.authData.contractorId, false, false, false, true)
                }

                $q.all(requests).then(function (results) {
                    let contractors = results.getContractors;
                    $scope.allDepartments = results.getDepartmentsWebapi;
                    let departments = $state.current.name === 'equipments' ? $scope.allDepartments : $scope.allDepartments.filter(d => d.locationNumber == null);
                    let vesselLocation = results.getVesselLocations;

                    var locationNumbersOnDepartments = $scope.allDepartments.filter(x => x.locationNumber !== null).map(x => x.locationNumber);
                    let locations =  $state.current.name === 'equipments' ? results.getAvailableLocationsByUserId.filter(x => !locationNumbersOnDepartments.includes(x.locationNumber)) : results.getAvailableLocationsByUserId;

                    if (contractors || contractors.length) {
                        var currentContractor = _.find(contractors, {
                            contractorId: $scope.authData.contractorId
                        });

                        if (currentContractor && currentContractor.locationOwnerId) {
                            locations = _.filter(locations, function (location) {
                                return location.locationAccessInfo.isContractorLocation || location.locationAccessInfo.isOwnedLocation;
                            });
                        }

                        vesselLocation = _.filter(vesselLocation, function (location) {
                            return (
                                currentContractor && location.contractorId === currentContractor.contractorId.toString()
                            );
                        });
                    }

                    for (var i = 0; i < locations.length; ++i) {
                        var locationDisplayName = '';
                        if (locations[i].locationNumber > 1000000000) {
                            locationDisplayName = locations[i].name;
                        } else {
                            locationDisplayName = locations[i].locationNumber + ' - ' + locations[i].name;
                        }

                        $scope.searchItems.push({
                            id: locations[i].locationNumber,
                            name: locations[i].name,
                            locationNumber: locations[i].locationNumber,
                            displayName: locationDisplayName,
                            type: 'Location',
                            lng: locations[i].x,
                            lat: locations[i].y
                        });
                    }

                    for (var i = 0; i < vesselLocation.length; ++i) {
                        if (vesselLocation[i].mmsi) {
                            $scope.searchItems.push({
                                id: vesselLocation[i].mmsi,
                                name: vesselLocation[i].shipname,
                                departmentId: vesselLocation[i].departmentId,
                                callsign: vesselLocation[i].callsign,
                                displayName: composeDisplayName(vesselLocation[i]),
                                type: 'Vessel',
                                lng: vesselLocation[i].lon,
                                lat: vesselLocation[i].lat
                            });
                        }
                    }

                    for (var i = 0; i < departments.length; ++i) {
                        if (!departments[i].mmsi && departments[i].bargeId == null) {
                            $scope.searchItems.push({
                                id: departments[i].departmentId,
                                name: departments[i].name,
                                departmentId: departments[i].departmentId,
                                callsign: departments[i].departmentInfo !== null ? departments[i].departmentInfo.callSign : '',
                                displayName: departments[i].name,
                                type: 'Department',
                                lng: departments[i].departmentInfo !== null ? departments[i].departmentInfo.longitude : '',
                                lat: departments[i].departmentInfo !== null ? departments[i].departmentInfo.latitude : ''
                            });
                        } else if(departments[i].bargeId != null) {
                            $scope.searchItems.push({
                                id: departments[i].departmentId,
                                name: departments[i].name,
                                departmentId: departments[i].departmentId,
                                callsign: departments[i].departmentInfo !== null ? departments[i].departmentInfo.callSign : '',
                                displayName: departments[i].name,
                                type: 'Barge',
                                lng: departments[i].departmentInfo !== null ? departments[i].departmentInfo.longitude : '',
                                lat: departments[i].departmentInfo !== null ? departments[i].departmentInfo.latitude : ''
                            });
                        }
                    }

                    const typeSort = {
                        'Location': 0,
                        'Vessel': 10,
                        'Department': 20,
                        'Barge': 30
                    };

                    $scope.searchItems = _.orderBy($scope.searchItems, [(item) => typeSort[item.type], (item) => item.type === 'Location' ? item.locationNumber : item.displayName]);

                    $scope.filteredSearchItems.length = 0;

                    for (var j = 0; j < $scope.searchItems.length; j++) {
                        $scope.filteredSearchItems.push($scope.searchItems[j]);
                    }

                    $scope.searchOptions.dataSource.read();

                    initSearchByState($state.current, $state.params);

                    if ($state.params.DepartmentId) {
                        $scope.department = $scope.allDepartments.find(x => x.departmentId === $state.params.DepartmentId)
                    }
                });
            }

            //Show/hide to map button
            if ($state.current.name === 'locationOverview'
                || $state.current.name === 'cases'
                || $state.current.name === 'equipments'
                || $state.current.name === 'operationLogsOverview'
                || $state.current.name.includes('timeBank')
                || $state.current.name === 'serviceforms'
                || $state.current.name === 'privacyAgreement'
                || $state.current.name === 'administration'
                || $state.current.name === 'vesselDashboard'
                || $state.current.name === 'customers'
                || $state.current.name === 'workorders') {
                $scope.showMapNavigation = true;
            } else {
                $scope.showMapNavigation = false;
            }
        }

        function initSearchByState(state, params) {
            if (state.name === 'equipments' || state.name === 'vesselDashboard') {
                $scope.searchFilter('Vessel');
                if (params.DepartmentId) {
                    var currentDepartment = $scope.allDepartments.find(x => x.departmentId === params.DepartmentId);
                    if (currentDepartment) {
                        var relatedItem;
                        if (currentDepartment.mmsi)
                            relatedItem = _.find($scope.searchItems, {
                                id: currentDepartment.mmsi
                            });
                        else
                            relatedItem = _.find($scope.searchItems, {
                                id: currentDepartment.departmentId
                            });
                        $scope.searchBoxText = relatedItem.displayName;
                    }
                }
            } else if (state.name === 'main.vesselDetails' && params.mmsi) {
                var relatedItem = _.find($scope.searchItems, {
                    id: params.mmsi
                });
                if (relatedItem) {
                    $scope.searchBoxText = relatedItem.displayName;
                }
            }
        }

        //$scope's functions
        function logout() {
            cacheService.clear().finally(function () {
                authService.logout();
                workOrderDetailsModalService.flush();
                $state.go('login');
            });
        }

        function login() {
            $state.go('login', {
                returnState: $state.current.name,
                returnParams: $state.params
            });
        }

        function changePassword() {
            userService.isMicrosoftAuthUser($rootScope.authData.userId)
                .then((result) => {
                    if (result) {
                        const message = `${$translate.instant('CHANGE_PASSWORD_ADUSER_MSG')}<br><a target="_blank" href="https://support.microsoft.com/nb-no/account-billing/endre-passordet-for-microsoft-kontoen-fdde885b-86da-2965-69fd-4871309ef1f1">${$translate.instant('CHANGE_PASSWORD_ADUSER_LINK_MSG')}</a>`;
                        confirmDialogService.openWarningDialog(message, $translate.instant('G_WARNING'));
                    } else {
                        $kWindow.open({
                            options: {
                                modal: true,
                                movable: false,
                                title: $translate.instant('CHANGE_PASSWORD_TITLE'),
                                resizable: false,
                                width: 450,
                                visible: false
                            },
                            templateUrl: 'app/password/change-password-modal.html',
                            windowTemplateUrl: 'app/shared/modal-base.html',
                            controller: 'ChangePasswordController'
                        });
                    }
                });
        }

        function changeContractorDepartment() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('MENU_CHANGE_CUSTOMER'),
                    resizable: false,
                    visible: false,
                    width: 600,
                },
                templateUrl: 'app/contractor/change-contractor-department.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ChangeContractorDepartmentController'
            });
        }

        function openOperationLogs() {
            $state.go('operationLogsOverview');
        }

        function search(item, e) {
            $rootScope.$broadcast('searchLocation', {
                message: 'Henter lokasjon'
            });
            var dataItem;

            if (!item) {
                for (var i = 0; i < $scope.searchItems.length; i++) {
                    if ($scope.searchItems[i].displayName === e) dataItem = $scope.searchItems[i];
                }
            } else {
                dataItem = item.dataItem(e.item.index());
            }
            if (dataItem) {
                $rootScope.$broadcast('navbarItemSelected', dataItem);
                if ($state.current.name !== 'equipments' && $state.current.name !== 'vesselDashboard') {
                    var authData = authService.getAuthData();
                    if (authData.isAuth) {
                        switch (dataItem.type) {
                            case 'Vessel':
                                $state.go('main.vesselDetails', {
                                    mmsi: dataItem.id
                                });
                                break;
                            case 'Department':
                                break;
                            case 'Location':
                                $state.go('main.locationInstallations', {
                                    LocationNumber: dataItem.id
                                });
                                break;
                        }
                    }
                }
            }
        }

        function searchFilter(searchFilterType) {
            focus('main-search-box');

            //TODO: localization
            $scope.searchBoxText = '';
            $scope.searchFilterType = searchFilterType;

            let placeholderName = getPlaceholderName($translate.use(), $scope.searchFilterType);
            setAttribute('main-search-box', 'placeholder', placeholderName);
            $scope.filteredSearchItems.length = 0;

            for (var i = 0; i < $scope.searchItems.length; i++) {
                if (
                    ($scope.searchItems[i].type === 'Department' ? 'Vessel' : $scope.searchItems[i].type) ===
                    searchFilterType ||
                    searchFilterType === 'All'
                ) {
                    $scope.filteredSearchItems.push($scope.searchItems[i]);
                }
            }
            $scope.searchOptions.dataSource.read();
        }

        function pressedSearch() {
            if ($scope.searchBoxText !== '' && $scope.searchBox && $scope.searchBox.dataItems()) {
                //If textbox value is not full displayname, set value as well
                var firstItem = $scope.searchBox.dataItems()[0];

                if (firstItem.displayName !== $scope.searchBoxText) {
                    $scope.searchBox.search($scope.searchBoxText);
                    $scope.searchBox.select($scope.searchBox.ul.children().eq(0));

                    $scope.searchBoxText = firstItem.displayName;
                }

                //Broadcast item selected
                $rootScope.$broadcast('navbarItemSelected', firstItem);

                //Depending upon current state, zoom to map or filter page
                if ($state.current.name !== 'equipments') {
                    $rootScope.$broadcast('zoomToLocation', { x: firstItem.lng, y: firstItem.lat }, firstItem);

                    var authData = authService.getAuthData();
                    if (authData.isAuth) {
                        //Load location specific orders
                        $state.go('main.locationInstallations', {
                            LocationNumber: firstItem.id
                        });
                    }
                }
            }
        }

        function navigateToCustomers()
        {
            $kWindow.open({
               options: {
                   modal: true,
                   movable: false,
                   title: $translate.instant('LOCATION_REFERENCES'),
                   resizable: true,
                   width: 1200,
                   visible: false
               },
               templateUrl: 'app/customers/customers-view.html',
               windowTemplateUrl: 'app/shared/modal-base.html',
               controller: 'CustomersController',
               resolve: {
                   departmentId: function () {
                       return $scope.authData.activeDepartment ? $scope.authData.activeDepartment.departmentId : null;
                   },
                   formType: function () {
                       return formTypes.add;
                   },
                   relatedItemId: function () {
                       return null;
                   },
                   operationLog: function () {
                       return null;
                   }
               }
            });
        }

        function openNewLogModal() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('OPERATIONLOG_NEW'),
                    resizable: true,
                    width: 1200,
                    visible: false
                },
                templateUrl: 'app/log/operation-log-extdetails-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationLogExtDetailsModalController',
                resolve: {
                    departmentId: function () {
                        return $scope.authData.activeDepartment ? $scope.authData.activeDepartment.departmentId : null;
                    },
                    formType: function () {
                        return formTypes.add;
                    },
                    relatedItemId: function () {
                        return null;
                    },
                    operationLog: function () {
                        return null;
                    }
                }
            });
        }

        function openNewWorkOrderModal() {
            $q.all([
                //TODO: cache regular requests
                departmentService.getDepartmentsWebapi($rootScope.authData.contractorId),
                userService.getUsersAsDropdownByFilter({contractorIds: [$rootScope.authData.contractorId]})
            ]).then(function (data) {
                //TODO: statuses move to global config
                var resolve = {
                    statuses: function () {
                        return workOrderStatuses;
                    },
                    departments: function () {
                        return data[0];
                    },
                    workOrderLog: function () {
                        return null;
                    },
                    modalType: function () {
                        return formTypes.add;
                    },
                    caseData: function () {
                        return null;
                    },
                    users: function () {
                        var modifiedData = data[1]
                            .concat([noUser])
                            .sort(function (a, b) {
                                if (a.displayName < b.displayName) {
                                    return -1;
                                }
                                if (a.displayName > b.displayName) {
                                    return 1;
                                }
                                return 0;
                            });
                        return modifiedData;
                    }
                };

                workOrderDetailsModalService.createWorkOrderDetailsResolve(null, null).then(function (resolve) {
                    workOrderDetailsModalService.openWorkOrderDetailsModal(resolve);
                });
            });
        }

        function openNewCaseModal() {
            caseDetailsModalService.createCaseDetailsResolve().then(function (resolve) {
                caseDetailsModalService.openCaseDetailsModal(resolve);
            });
        }

        function onStateChanged(event, toState, toParams, fromState, fromParams) {
            initSearchByState(toState, toParams);
        }

        function composeDisplayName(vessel) {
            let displayName = '';
            if (vessel.mmsi) {
                displayName += vessel.mmsi;
            }
            if (vessel.mmsi && vessel.shipname) {
                displayName += ' - ';
            }
            if (vessel.shipname) {
                displayName += vessel.shipname;
            }
            if (vessel.callsign) {
                displayName += ' (' + vessel.callsign + ')';
            }
            return displayName;
        }

        function toMapView() {
            var locationNumber = $state.params.LocationNumber,
                currentStateName = $state.current.name;

            //Close all busy indicator
            $rootScope.$broadcast('hideAllBusyIndicator');

            //Navigation is based on the current state
            if (currentStateName === 'locationOverview') {
                if (locationNumber) {
                    $state.go('main.locationInstallations', {
                        LocationNumber: locationNumber
                    });
                } else {
                    $state.go('main');
                }
            } else if (currentStateName === 'equipments') {
                if ($state.params.DepartmentId) {
                    departmentService.getDepartmentByIdWebApi($state.params.DepartmentId).then(function (department) {
                        $state.go('main.vesselDetails', {
                            mmsi: department.mmsi
                        });
                    });
                } else {
                    $state.go('main');
                }
            } else {
                $state.go('main');
            }
        }

        function navigateToEquipments() {
            navigateToStateIfMmsiSupplied('equipments');
        }

        function navigateToVesselDashboard() {
            navigateToStateIfMmsiSupplied('vesselDashboard');
        }

        function navigateToProductRegister() {
            navigateToStateIfMmsiSupplied('product-register');
        }

        function navigateToStateIfMmsiSupplied(state) {
            if ($state.params.mmsi) {
                departmentService
                    .getDepartmentByMMSIWebAPI($scope.authData.contractorId, $state.params.mmsi)
                    .then(function (department) {
                        if (department) {
                            $state.go(state, {
                                DepartmentId: department.departmentId
                            });
                        } else {
                            $state.go(state);
                        }
                    });
            } else {
                $state.go(state);
            }
        }

        $scope.editDepartmentStatus = () => {
            const window = $kWindow.open({
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/department/department-status-modal.html',
                controller: 'departmentStatusModalController',
                resolve: {
                    department: () => $scope.department,
                },
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 600,
                    title: $translate.instant('MENU_MODAL_SETTINGS')
                }
            });

            window.result.then(result => {
                if (result) {
                    initController();
                }
            });
        }

        //Private functions
        function getPlaceholderName(langId, searchFilterType) {
                switch (searchFilterType) {
                    case 'All':
                        return 'Alle...'; //$translate.instant('NAVBAR_SEARCH_ALL', null, null, langId);
                    case 'Location':
                        return 'Skriv inn navn eller nummer på lokalitet...'; //$translate.instant('NAVBAR_SEARCH_LOCATION', null, null, langId);
                    case 'Vessel':
                        return $translate.instant('NAVBAR_SEARCH_DEPARTMENT', null, null, langId);
                }

                return 'Alle...';//$translate.instant('NAVBAR_SEARCH_ALL', null, null, langId);
        }

        $scope.openOperationHub = function(){
            $window.open(serviceUrls.naviaqHubBaseUrl + '?token=' + $scope.authData.token + '&userId=' + $scope.authData.userId, '_blank');
        }

        $scope.openHrOperationHub = function(){
            $window.open(serviceUrls.naviaqHubBaseUrl + '/hr-overview?token=' + $scope.authData.token + '&userId=' + $scope.authData.userId, '_blank');
        }
    }
})();
