(function () {
    var naviaqWebApp = angular.module('NaviaqWebApp', [
        'kendo.directives',
        'kendo.window',
        'ui.router',
        'LocalStorageModule',
        'LocalForageModule',
        'esri.map',
        'angularjs-dropdown-multiselect',
        'pascalprecht.translate',
        'ngCookies',
        'textAngular'
    ]);

    naviaqWebApp.config([
        '$locationProvider',
        '$httpProvider',
        function ($locationProvider, $httpProvider) {
            $locationProvider.html5Mode(true);
            $httpProvider.interceptors.push('authInterceptorService');
            $httpProvider.interceptors.push('cacheInterceptorService');
        },
    ]);

    naviaqWebApp.factory('sanitize', [function () {
        function escapeHtml(html) {
            var div = document.createElement('div');
            div.textContent = html;
            return div.innerHTML;
        }
        return escapeHtml;
    }]);

    var translateService = angular.injector(['pascalprecht.translate']).get('$translate');

    naviaqWebApp.constant('clientType', 'WebApp');

    naviaqWebApp.constant('formTypes', {
        add: 'add',
        edit: 'edit',
        readOnly: 'readOnly',
        disabled: 'disabled',
    });
    naviaqWebApp.constant('froyContractorId', '20856e42-ed50-e711-93a3-f23cffe3d187');
2
    naviaqWebApp.constant('froyConglomerateName', 'Frøy');

    naviaqWebApp.constant('OrderStatus', {
        InProgress: 0,
        Active: 1,
        Completed: 2,
        ReadyForInvoicing: 3,
        Invoiced: 4,
        Quote: 5,
        Confirmed: 6,
        Planned: 7,
        Cancelled: 8,
        Archived: 9,
        ShallNotBeInvoiced: 10,
        Monthlyinvoice: 11,
        InternalInvoice: 12,
        Deleted: 99
    });

    naviaqWebApp.constant('externalReportType', {
        AquaScan: 0,
        MmcOzone: 1,
        MmcPipe: 2,
        MmcWell: 3,
        WaterQuality: 4,
        HidrogenPeroxide: 5,
    });
    naviaqWebApp.constant('logActionTypes', {
        operationLog: 0,
        vesselLog: 1,
        operationAndVessel: 2,
        deviationLog: 3,
        timeBankLog: 4,
        videoLog: 5,
        workOrderLog: 6,
    });
    naviaqWebApp.constant('logActionJobType', {
        log: 0,
        scheduled: 1,
        corrective: 2,
    });

    naviaqWebApp.constant('DepartmentStatus', {
        Inactive: 0,
        Active: 1,
        Paused: 2,
    });

    naviaqWebApp.constant('mapObjectType', {
        Barge: 0,
        Cage: 1,
        Net: 2,
        Ring: 3,
        MapLayerObject: 4,
    });
    naviaqWebApp.constant('operationLogStatus', {
        Registered: 0,
        Approved: 1,
        Started: 2,
        Completed: 3,
        Closed : 4,
        Rejected : 5,
        Confirmed: 6,
        Deleted: 99,
    });
    naviaqWebApp.constant('itemValidityIntervalType', {
        Hours: 0,
        Days: 1,
        Months: 2,
    });
    naviaqWebApp.constant('locationObjectUsageState', {
        Free: 0,
        PlannedToUse: 1,
        InUse: 2,
        Discarded: 3,
        Active: 4,
        InStock: 5,
        Impregnated: 6,
        Washed: 7,
        Received: 8,
        Idle: 9,
    });
    naviaqWebApp.constant('itemtreeKeys', {
        Equipment: 'EQUIP',
        Report: 'REPORT',
        Department: 'DOC-DEPARTMENT',
    });
    naviaqWebApp.constant('itemStatus', {
        Active: 0,
        Archived: 1,
    });

    naviaqWebApp.provider('itemStatusObject', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return [
                    { value: 0, name: $translate.instant('ITEM_ACTIVE') },
                    { value: 1, name: $translate.instant('ITEM_ARCHIVE') }
                ];
            },
        ];
    });

    naviaqWebApp.constant('logActionStatus', {
        Active: 0,
        InActive: 1,
        active: 0,
        inActive: 1
    });

    naviaqWebApp.constant('generalComponentStatus', {
        Active: 0,
        Inactive: 1,
        Archived: 2,
        Deleted: 99,
    });

    naviaqWebApp.constant('controlType', {
        TextBox: 0,
        ComboBox: 1,
        CheckBox: 2,
        DateTime: 3,
        TimeSpan: 4,
        NumericUpDown: 5,
        PageBreak: 6,
        Link: 7,
        TouchDateTime: 8,
        MaskedTextBox: 9,
        Image: 10,
        Separator: 11,
        Button: 12,
        RadioButton: 13,
        Label: 14,
        MessageBox: 15,
        UserControl: 16,
        DynamicComboBox: 17,
        DateTimeIntervalPicker: 18,
        Table: 19,
        TextArea: 20,
        ToggleButton: 21,
        FullDateTime: 22,
        AdvancedTable: 23,
        ImageEditor: 24,
    });

    naviaqWebApp.constant('editableOrderState', {
        InProgress: 0,
        Active: 1,
    });

    naviaqWebApp.constant('operationTypeStatus', {
        Active: 0,
        Archived: 1,
    });

    naviaqWebApp.constant('roleAccess', {
        menu: {
            invoice: [
                'DeckHand',
                'DeviationLogAdmin',
                'InvoiceUser',
                'LocationOwnerAdmin',
                'LocationOwnerViewer',
                'Management',
                'MooringFieldUser',
                'MooringManager',
                'NetAdmin',
                'NetFieldUser',
                'ProjectManager',
                'SuperUser',
            ],
            newInvoice: [
                'LocationOwnerAdmin',
                'Management',
                'MooringFieldUser',
                'MooringManager',
                'NetAdmin',
                'NetFieldUser',
                'ProjectManager',
                'SuperUser',
            ],
            operationLog: [
                'DeckHand',
                'DeviationLogAdmin',
                'InvoiceUser',
                'MooringFieldUser',
                'ProjectManager',
                'Skipper',
                'SuperUser',
            ],
            component: [
                'SuperUser',
                'MooringFieldUser',
                'MooringManager',
                'LocationOwnerAdmin',
                'NetAdmin',
                'NetFieldUser',
                'Supplier',
            ],
            administration: ['Administrator', 'ProjectManager', 'SuperUser'],
            workorders: ['Administrator', 'ProjectManager', 'SuperUser'],
        },
    });

    naviaqWebApp.constant('deviationStatuses', [
        {
            Id: '11111111-1111-1111-1111-111111111111',
            Value: 0,
            Name: 'Åpen'
        },
        {
            Id: '22222222-2222-2222-2222-222222222222',
            Value: 1,
            Name: 'Under behandling'
        },
        {
            Id: '77777777-7777-7777-7777-777777777777',
            Value: 1,
            Name: 'Eksportert'
        },
        {
            Id: '99999999-9999-9999-9999-999999999999',
            Value: 2,
            Name: 'Lukket'
        }
    ]);

    naviaqWebApp.constant('inspectionOptions', [
        {
            name: 'Kontrollert og funnet i orden',
            value: 0,
        },
        {
            name: 'Avvik',
            value: 1,
        },
        {
            name: 'Ikke kontrollert',
            value: 2,
        },
        {
            name: 'Annet',
            value: 3,
        },
        {
            name: 'Ikke montert',
            value: 4,
        }
    ]);

    naviaqWebApp.constant('serviceFormTypes', [
        {
            Value: 0,
            Name: 'Input',
            type: "input"
        },
        {
            Value: 1,
            Name: 'TextboxArea',
            type: "textarea"
        },
        {
            Value: 2,
            Name: 'CheckBox',
            type: "checkbox"
        },
        {
            Value: 3,
            Name: 'Select(CustomOptions)',
            type: "select"
        },
        {
            Value: 4,
            Name: 'Multi-Select(CustomOptions)',
            type: "multiselect"
        },
        {
            Value: 5,
            Name: 'Select(PreLoadData)',
            type: "select"
        },
        {
            Value: 6,
            Name: 'Multi-Select(PreLoadData)',
            type: "multiselect"
        },
        {
            Value: 7,
            Name: 'DateTime',
            type: "datetime"
        },
        {
            Value: 8,
            Name: 'Date',
            type: "date"
        },
        {
            Value: 9,
            Name: 'Time',
            type: "time"
        },
        {
            Value: 10,
            Name: 'Header',
            type: "header"
        },
        {
            Value: 11,
            Name: 'Inspection select',
            type: "inspection-select"
        },
        {
            Value: 12,
            Name: 'Operationlog time',
            type: "netwashtime"
        },
        {
            Value: 13,
            Name: 'Multilevel dive depth',
            type: "multilevelDiveDepth"
        },
        {
            Value: 14,
            Name: 'Number',
            type: "number"
        },
        {
            Value: 15,
            Name: 'File upload',
            type: "file",
        },
        {
            Value: 16,
            Name: 'Information Area',
            type: "information",
        },
        {
            Value: 17,
            Name: 'Numeric aggregation',
            type: "numericAggregation",
        },
        {
            Value: 18,
            Name: 'Date aggregation',
            type: "dateAggregation",
        },
        {
            Value: 19,
            Name: 'Generic competency',
            type: "genericCompetemcy",
        },
        {
            Value: 20,
            Name: 'Photo upload',
            type: "photoUpload",
        },
        {
            Value: 21,
            Name: 'Inspection select - strict',
            type: "inspection-select-strict"
        },
    ]);

    naviaqWebApp.constant('PreLoadDataOptions', [
        {
            Value: 'department',
            Name: 'Department',
            isShowForCompetency: true
        },
        {
            Value: 'logaction',
            Name: 'LogAction',
            isShowForCompetency: false
        },
        {
            Value: 'user',
            Name: 'User',
            isShowForCompetency: true
        },
        {
            Value: 'diver',
            Name: 'Diver',
            isShowForCompetency: false
        },
        {
            Value: 'diverLeader',
            Name: 'Diver leader',
            isShowForCompetency: false
        },
        {
            Value: 'buoys',
            Name: 'Buoys',
            isShowForCompetency: false
        }
    ]);

    naviaqWebApp.provider('workflowStatuses', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
                return [
                    {
                        value: '11111111-1111-1111-1111-111111111111',
                        text: $translate.instant('CASE_STATUS_OPEN', null, null, langId),
                        meaning: 'Open',
                    },
                    {
                        value: '22222222-2222-2222-2222-222222222222',
                        text: $translate.instant('CASE_STATUS_INPROGRESS', null, null, langId),
                        meaning: 'InProgress',
                    },
                    {
                        value: '77777777-7777-7777-7777-777777777777',
                        text: $translate.instant('CASE_STATUS_EXPORTED', null, null, langId),
                        meaning: 'Exported',
                    },
                    {
                        value: '99999999-9999-9999-9999-999999999999',
                        text: $translate.instant('CASE_STATUS_CLOSED', null, null, langId),
                        meaning: 'Closed',
                    },
                ];
            },
        ];
    });

    naviaqWebApp.provider('departmentMainTypes', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
                return [
                    {
                        value: 0,
                        text: $translate.instant('ADMIN_VESSEL_TYPE_VESSEL', null, null, langId)
                    },
                    {
                        value: 1,
                        text: $translate.instant('ADMIN_VESSEL_TYPE_LOCATION', null, null, langId)
                    },
                    {
                        value: 2,
                        text: $translate.instant('ADMIN_VESSEL_TYPE_ORGANIZATIONAL', null, null, langId)
                    },
                    {
                        value: 3,
                        text: $translate.instant('ADMIN_VESSEL_TYPE_BARGE', null, null, langId)
                    },
                ];
            },
        ];
    });

    naviaqWebApp.provider('severityList', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return [
                    {
                        text: $translate.instant('CASE_SEVERITY_LOW'),
                        value: 0
                    },
                    {
                        text: $translate.instant('CASE_SEVERITY_MEDIUM'),
                        value: 1
                    },
                    {
                        text: $translate.instant('CASE_SEVERITY_HIGH'),
                        value: 2
                    },
                ];
            },
        ];
    });

    naviaqWebApp.constant('workOrderStatuses', [
        { value: 0, text: 'Ubehandlet', meaning: 'Untreated', enum: 'Registered' },
        { value: 1, text: 'Planlagt', meaning: 'Planned', enum: 'Approved' },
        { value: 2, text: 'Aktiv', meaning: 'Active', enum: 'Started' },
        { value: 3, text: 'Gjennomført', meaning: 'Accomplished', enum: 'Completed' },
        { value: 4, text: 'Lukket', meaning: 'Closed', enum: 'Closed' },
        { value: 5, text: 'Avvist', meaning: 'Rejected', enum: 'Rejected' },
        { value: 99, text: 'Slettet', meaning: 'Deleted', enum: 'Deleted'}
    ]);

    naviaqWebApp.constant('workContractTypes', [
        { value: 0, text: 'Fast ansatt'},
        { value: 1, text: 'Vikar'},
        { value: 2, text: 'Prøvetur'}
    ]);

    naviaqWebApp.constant('serviceFormReportTypes', [
        { name: 'Kombi'},
        { name: 'H202'},
    ]);

    naviaqWebApp.provider('months', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return [
                    $translate.instant('TIMEBANK_BALANCE_MONTH_JANUARY'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_FENRUARY'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_MARCH'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_APRIL'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_MAY'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_JUNE'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_JULY'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_AUGUST'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_SEPTEMBER'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_OCTOBER'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_NOVEMBER'),
                    $translate.instant('TIMEBANK_BALANCE_MONTH_DECEMBER')
                ];
            },
        ];
    });

    naviaqWebApp.provider('noUser', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return {
                    userId: '00000000-0000-0000-0000-000000000000',
                    displayName: $translate.instant('G_NOTASSIGNED'),
                };
            },
        ];
    });

    naviaqWebApp.provider('jobTitles', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return [
                    { text: $translate.instant('ADMIN_USER_JOB_TITLE_DEFAULT'), value: 0 },
                    { text: $translate.instant('ADMIN_USER_JOB_TITLE_DIVER'), value: 1 },
                    { text: $translate.instant('ADMIN_USER_JOB_TITLE_PROJECT_MANAGER'), value: 2 },
                ];
            },
        ];
    });

    naviaqWebApp.constant('captainMessageLogActionId', '527BEED0-ED88-4A8E-8BE8-D4A2F86BA9B5');

    const dataTypes = {
        text: 'string',
        number: 'numeric',
        date: 'date',
        datetime: 'datetime',
        time: 'time',
    };

    naviaqWebApp.constant('propertyFields', [
        { id: 0, text: 'Description', type: dataTypes.text },
        { id: 1, text: 'Comment', type: dataTypes.text },
        { id: 2, text: 'CreatedOn', type: dataTypes.datetime },
        { id: 3, text: 'CreatedBy', type: dataTypes.text },
        { id: 4, text: 'EditedOn', type: dataTypes.datetime },
        { id: 5, text: 'EditedBy', type: dataTypes.text },
        { id: 6, text: 'Value', type: dataTypes.number },
        { id: 7, text: 'DoneOn', type: dataTypes.datetime },
        { id: 8, text: 'DoneOn.Time', type: dataTypes.time },
        { id: 9, text: 'DoneOn.Date', type: dataTypes.date },
    ]);

    naviaqWebApp.constant('processDocumentType', {
        0: 'Ringvask',
        1: 'Notinspeksjon',
        2: 'Fortroyingsarbeid',
        3: 'Dykking',
        4: 'WaterSurvey'
    });

    naviaqWebApp.constant('electricity',
       [
        { value: 230, text: '230'},
        { value: 400, text: '400'},
        { value: 230400, text: '230/400'}
       ]
    );

    naviaqWebApp.provider('externalCodeTypes', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return [
                    { id: 0, value: $translate.instant('LOGACTIONPAIRS_EXTTYPE_DROPDONW_ABSENSE')},
                    { id: 1,  value: $translate.instant('LOGACTIONPAIRS_EXTTYPE_DROPDONW_PAYMENT')}
                ];
            },
        ];
    });

    naviaqWebApp.provider('entityHistoryPropertyDictionaryKeys', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return {
                    contractorId: $translate.instant('G_CONTRACTOR'),
                    departmentId: $translate.instant('G_DEPARTMENT'),
                    logActionId: $translate.instant('TIMEBANK_LOG_GRID_START_ACTION'),
                    description: $translate.instant('TIMEBANK_LOG_GRID_DESCRTIPTION'),
                    comment: $translate.instant('ENTITY_HISTORY_COMMENT'),
                    createdOn: $translate.instant('G_CREATED_ON'),
                    createdBy: $translate.instant('G_CREATED_BY'),
                    editedOn: $translate.instant('G_EDITED_ON'),
                    editedBy: $translate.instant('G_EDITED_BY'),
                    status: $translate.instant('TIMEBANK_LOG_GRID_STATUS'),
                    severity: $translate.instant('ENTITY_HISTORY_SEVERITY'),
                    doneOn: $translate.instant('ENTITY_HISTORY_DONE_ON'),
                    cause: $translate.instant('ENTITY_HISTORY_CAUSE')
                };
            },
        ];
    });

    naviaqWebApp.provider('entityHistoryValueDictionaryKeys', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return {
                    OperationLogStatus0: $translate.instant('TIMEBANK_STATUS_REGISTERED'),
                    OperationLogStatus1: $translate.instant('TIMEBANK_STATUS_APPROVED'),
                    OperationLogStatus2: $translate.instant('TIMEBANK_STATUS_STARTED'),
                    OperationLogStatus3: $translate.instant('TIMEBANK_STATUS_COMPLETED'),
                    OperationLogStatus4: $translate.instant('TIMEBANK_STATUS_CLOSED'),
                    OperationLogStatus5: $translate.instant('TIMEBANK_STATUS_REJECTED'),
                    OperationLogStatus6: $translate.instant('TIMEBANK_LOG_TOOLTIP_CONFIRMED'),
                    OperationLogStatus99: $translate.instant('TIMEBANK_STATUS_DELETED'),
                    OperationLogSeverity0: $translate.instant('CASE_SEVERITY_LOW'),
                    OperationLogSeverity1: $translate.instant('CASE_SEVERITY_MEDIUM'),
                    OperationLogSeverity2: $translate.instant('CASE_SEVERITY_HIGH')
                };
            },
        ];
    });

    naviaqWebApp.provider('documentTypeAccess', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return [
                    { id: 0, value: $translate.instant('ADMIN_DOCUMENT_ACCESS_LEVEL_PRIVATE')},
                    { id: 1,  value: $translate.instant('ADMIN_DOCUMENT_ACCESS_LEVEL_PUBLIC')},
                    { id: 2,  value: $translate.instant('ADMIN_DOCUMENT_ACCESS_LEVEL_SHARED')}
                ];
            },
        ];
    });

    naviaqWebApp.provider('logActionPairsStatus', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return [
                    { value: 0, text: $translate.instant('TIMEBANK_STATUS_REGISTERED')},
                    { value: 1,  text: $translate.instant('TIMEBANK_STATUS_APPROVED')},
                    { value: 4,  text: $translate.instant('TIMEBANK_STATUS_CLOSED')},
                    { value: 5,  text: $translate.instant('TIMEBANK_STATUS_REJECTED')},
                    { value: 6,  text: $translate.instant('TIMEBANK_LOG_TOOLTIP_CONFIRMED')}
                ];
            },
        ];
    });

    naviaqWebApp.constant('balanceTypes', [
        {value : 'Hourly', name : 'Hourly'},
        {value : 'Absence Hourly', name : 'Absence Hourly'},
        {value : 'Absence Daily', name : 'Absence Daily'},
        {value : 'Daily', name : 'Daily'},
        { value: 'Daily - Full Day', name: 'Daily - Full Day' },
        { value: 'Night Shift', name: 'Night Shift' }
    ]);

    naviaqWebApp.provider('componentTypes', function () {
        this.$get = [
            '$translate',
            function ($translate) {
                return [
                    { value: 1, text: $translate.instant('ORDER_PROCESS_COMPONENT_RING')},
                    { value: 2, text: $translate.instant('ORDER_PROCESS_COMPONENT_NET')},
                    { value: 3, text: $translate.instant('ORDER_PROCESS_COMPONENT_CAGE')},
                    { value: 4, text: $translate.instant('ORDER_PROCESS_COMPONENT_LINE')},
                ];
            },
        ];
    });

    naviaqWebApp.constant('defaultWkid', '32233');
    naviaqWebApp.constant('systemUserName', 'NAVIAQ_SYSTEM');
    naviaqWebApp.constant('mediaOwnerId', '5885d1f8-8440-47da-baa4-98fa1473f23e');

    naviaqWebApp.constant('vesselSettings', {
        'rootCategoryKey': 'EQUIP'
    });

    naviaqWebApp.constant('visitorStates', [
        'main.orderReport',
        'orderWorkReport',
        'operationLogsReport',
        'serviceCard',
        'serviceCardPrint',
        'arrivalReport',
        'finalReport',
        'finalReportAQS',
        'finalReportFroy',
        'mooringReport',
        'mooringReportFroy',
        'mooringReportAQS',
        'orderConfirmationReport'
    ]);

    naviaqWebApp.constant('componentElementType', {
        Net: 0,
        Ring: 1,
        Cage: 2,
        Line: 3,
        LiceSkirt: 4,
        Bridles: 5,
    });

    naviaqWebApp.constant('kundeavvikLogactionId', 'b4789159-96d3-e911-8399-ba957be39aa1');

    naviaqWebApp.constant('env', {
        version: "241202142920"
    });

    naviaqWebApp.run([
        '$window',
        '$interval',
        '$rootScope',
        '$state',
        '$translate',
        'commonUtility',
        'kendoUtility',
        'visitorStates',
        'authService',
        'queryStringService',
        'froyContractorId',
        'confirmDialogService',
        'localStorageService',
        '$http',
        'env',
        function (
            $window,
            $interval,
            $rootScope,
            $state,
            $translate,
            commonUtility,
            kendoUtility,
            visitorStates,
            authService,
            queryStringService,
            froyContractorId,
            confirmDialogService,
            localStorageService,
            $http,
            env
        ) {
            var hasAppUpdate = false;

            if (window.location.hostname !== 'localhost') {
                $interval(() => {
                    $http({
                        method: 'GET',
                        url: '/settings.json',
                        headers: {
                            'pragma': 'no-cache',
                            'cache-control': 'no-cache'
                        }
                    })
                        .then((response) => {
                            const version = response.data.version;

                            if (version !== env.version) {
                                hasAppUpdate = true;
                            }
                        });
                }, 30000);
            }

            $rootScope.$on('$stateChangeSuccess', function (_, toState) {
                if(hasAppUpdate){
                    hasAppUpdate = false;
                    $window.location.reload();
                }
            });

            //Check if token value is provided
            let queryParams = commonUtility.getQueryParams($window.location.search);
            if (queryParams.Token && queryParams.ContractorId && queryParams.UserId) {
                authService.setAnonymous(queryParams.Token, queryParams.ContractorId, queryParams.UserId);
            } else {
                authService.fillAuthData();
            }

            $rootScope.authData = authService.getAuthData();
            $rootScope.hasPermission = authService.hasPermission; //Add hasPermission as a shortcut to root scope
            $rootScope.hasModule = authService.hasModule;
            $rootScope.hasRole = authService.hasRole;
            $rootScope.openAlertModal = false;

            $rootScope.hasBanOnModule = authService.hasBanOnModule;

            kendoUtility.applyLanguage($translate.use());

            $rootScope.isRequestFromOtherSite = null;
            $rootScope.thereWasRedirect = false;

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
                var authData = authService.getAuthData();

                //Check auth value changes
                if (authData.isAuth) {
                    if (toState.name === 'login') {
                        event.preventDefault();
                        if (queryStringService.getParameterByName('site')) {
                            if (!$rootScope.isRequestFromOtherSite) {
                                $rootScope.isRequestFromOtherSite = true;
                                $state.transitionTo('loginFromOtherSite', { site: queryStringService.getParameterByName('site'), redirectUrl: queryStringService.getParameterByName('redirectUrl') });
                            }
                        } else {
                            if (!$rootScope.isRequestFromOtherSite) {
                                $state.transitionTo('main');
                            }
                        }
                    } else if (!authService.isUpToDate()) {
                        event.preventDefault();
                        authService.logout();
                        $state.transitionTo('login');
                    }
                } else {
                    if (toState.name === 'login' && !$rootScope.thereWasRedirect) {
                        if (queryStringService.getParameterByName('site')) {
                            event.preventDefault();
                            $rootScope.thereWasRedirect = true;
                            $state.transitionTo('loginFromOtherSite', { site: queryStringService.getParameterByName('site'), redirectUrl: queryStringService.getParameterByName('redirectUrl') });
                        }
                    }
                    if (
                        toState.name !== 'login' &&
                        toState.name !== 'loginFromOtherSite' &&
                        toState.name !== 'forgotPassword' &&
                        toState.name !== 'passwordReset' &&
                        visitorStates.indexOf(toState.name) === -1
                    ) {
                        event.preventDefault();

                        $state.transitionTo('login');
                    }
                }
            });

            $rootScope.$on('$stateChangeSuccess', function (_, toState) {
                if (toState.name === 'login' || toState.name === 'loginFromOtherSite') {
                    $rootScope.showNavbar = false;
                } else {
                    $rootScope.showNavbar = true;
                    $rootScope.showMenu = $rootScope.authData.isAuth;
                }

                $rootScope.$broadcast('hideAllBusyIndicator');
            });

            $rootScope.$on('permissionDenied', (e, url) => {
                if($rootScope.openAlertModal == false){
                    $rootScope.openAlertModal = true;
                    const warningModal = getWarningModalContent(url);
                    confirmDialogService.openWarningDialog(warningModal.content, warningModal.title)
                                        .finally(() =>$rootScope.openAlertModal = false);
                }
            });

            $rootScope.$on('crossSiteScriptingError', (e) => {
                if($rootScope.openAlertModal == false){
                    $rootScope.openAlertModal = true;
                    const title = $translate.instant('G_XSS_WARNING_TITLE');
                    const content = $translate.instant('G_XSS_WARNING_CONTENT');
                    confirmDialogService.openWarningDialog(content, title)
                                        .finally(() =>$rootScope.openAlertModal = false);
                }
            });

            function getWarningModalContent(url) {
                switch (url) {
                    case 'operationLogs':
                        return {
                            content: $translate.instant('TIMEBANK_LOG_APPROVED_REST_HOURS_ERROR_MSG'),
                            title: $translate.instant('PERMISSION_ALERT_MODAL_TITLE')
                        };
                    case 'admin':
                        return {
                            content: $translate.instant('PERMISSION_ALERT_MODAL_FOREIGN_KEY_CONTEXT'),
                            title: $translate.instant('PERMISSION_ALERT_MODAL_FOREIGN_KEY_TITLE')
                        };
                    default:
                        return {
                            content: $translate.instant('PERMISSION_ALERT_MODAL_CONTEXT'),
                            title: $translate.instant('PERMISSION_ALERT_MODAL_TITLE')
                        };
                }
            }

            function showAlert(show = false){
                var showAlert = queryStringService.getParameterByName('showAlert');
                if(show || showAlert){
                    var site = queryStringService.getParameterByName('site');
                    var siteName = site ? 'Operation HUB' : 'Web App'
                    alert(`Du har ikke tilgang til ${siteName}, om dette er feil, vennligst kontakt administrator.`);
                }
            }

            function hasAccess(authData){
                if(authData.contractorId === froyContractorId){
                    if(authData.isSuperuser){
                        return true;
                    }else{
                        var orderAdministratorRole = authData.roles.find(r => r.key === 'OrderAdministrator')
                        if(!orderAdministratorRole){
                            return false
                        }
                        return true;
                    }
                }
                return true;
            }

            $rootScope.isSmallDevice = isMobileView();
            function isMobileView()
            {
                return ($('.device-xs').css('display') == 'none' || $('.device-sm').css('display') == 'none');
            }

            // Clear browser local storage when close tab if it's anonymous
            window.addEventListener("beforeunload", function(event) {
                var authData = localStorageService.get('authenticationData');
                if(authData && !authData.isAuth && !authData.username && !$state.current.url.includes('login')){
                    localStorageService.remove('authenticationData');
                    event.returnValue = null;
                }
            });
        },
    ]);

    naviaqWebApp.constant('defaultSelectedLanguageId', 'no');

    naviaqWebApp.constant('availableLanguages', [
        { id: 'en', name: 'English', icon: 'UnitedStates-Flag-48.png' }, // icon:"GreatBritain-Flag-48.png"
        { id: 'no', name: 'Norsk', icon: 'Norway-Flag-48.png' },
    ]);

    naviaqWebApp.config([
        '$translateProvider',
        'localStorageServiceProvider',
        'defaultSelectedLanguageId',
        'availableLanguages',
        function ($translateProvider, localStorageServiceProvider, defaultSelectedLanguageId, availableLanguages) {
            $translateProvider
                .useStaticFilesLoader({
                    prefix: 'app/locales/',
                    suffix: '.json',
                })
                .registerAvailableLanguageKeys(
                    availableLanguages.map((l) => l.id),
                    {
                        en: 'en',
                        no: 'no',
                    }
                )
                .preferredLanguage(defaultSelectedLanguageId)
                .fallbackLanguage('en')
                .useSanitizeValueStrategy('escapeParameters');
                $translateProvider.useLocalStorage();

            localStorageServiceProvider.setPrefix('naviaqWebApp').setNotify(false, false);
        },
    ]);
})();
