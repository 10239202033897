(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('finalReportFileListController', finalReportFileListController);

    finalReportFileListController.$inject = [
        '$scope',
        '$windowInstance',
        'files',
        'mediaService',
        '$translate',
        'kendoUtility',
        'confirmDialogService',
        'anonymousReportService'
    ];

    function finalReportFileListController(
        $scope,
        $windowInstance,
        files,
        mediaService,
        $translate,
        kendoUtility,
        confirmDialogService,
        anonymousReportService
    ) {

        $scope.filterDropDowns = {
            fileName: null
        };

        $scope.files = initFileListAndFilter(files);

        const filesGridDataSource = new kendo.data.DataSource({
            transport: {
                read: (e) => e.success($scope.files),
            },
            schema: {
                model: {
                    fields: {
                        fileName: { type: "object" },
                    }
                }
            }
        });

        const filesGridOptions = langId => ({
            dataSource: filesGridDataSource,
            sortable: true,
            filterable: true,
            filterMenuInit: kendoUtility.onFilterMenuInit,
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [
                {
                    field: 'fileName',
                    title: $translate.instant('CASE_FILES_GRID_FILENAME', null, null, langId),
                    template: function(dataItem)
                    {
                        if(!dataItem.file.naviaqMediaId){
                            return `<a target="_blank" href="${dataItem.url}">${dataItem.fileName}</a>`
                        } else {
                            return `<a ng-click="handleVideo(dataItem)" href="">${dataItem.fileName}</a>`
                        }
                    },
                    filterable: { ui: (element) => createDropDownFilter(element, 'fileName') }
                }
            ]
        });
        $scope.filesGridOptions = filesGridOptions($translate.use());

        function createDropDownFilter(element, field) {
            element.removeAttr("data-bind");
            element.kendoMultiSelect({
                dataSource: new kendo.data.DataSource({ data: $scope.filterDropDowns[field] }),
                dataTextField: "text",
                dataValueField: "value",
                change: function (e) {
                    var values = this.value();
                    var activeFilters = [];
                    $.each(values, function (i, v) {
                        activeFilters.push({ field: field, operator: "equal", value: v });
                    });
                    kendoUtility.createFilterOnGrid(filesGridDataSource, activeFilters, field);
                }
            });
            if (!document.getElementById(`${field}-filter-menu-selector`)) {
                $(element).context.id = `${field}-filter-menu-selector`;
            }
        }

        function initFileListAndFilter(files){
            files.forEach(fileItem => {
                fileItem.fileName = fileItem.file.fileName;
                if(!fileItem.file.naviaqMediaId){
                    fileItem.url = mediaService.createMediaUrlMediaBank(fileItem.file.mediaId);
                }
            });

            const fileNames = _.uniq(files.map(d => d.fileName));
            $scope.filterDropDowns.fileName = [];
            fileNames.forEach(name => name && $scope.filterDropDowns.fileName.push({ value: name, text: name }));
            return files;
        }

        $scope.handleVideo = function (video) {
            if (!video.url) {
                confirmDialogService.openWarningDialog($translate.instant("FINAL_REPORT_VIDEO_LINK_MISSING"), $translate.instant("FINAL_REPORT_VIDEO_LINK"));
            }
            else {
                if (!video.url.includes("blob.core.windows.net")) {
                    window.open(video.url, '_blank')
                } else {
                    confirmDialogService.openVideoDownloadWarningModal(video.sizeMb).then(function (response) {
                        if (response) {
                            anonymousReportService.downloadOrderRelatedVideo(video).then(url => {
                                window.open(url, '_blank')
                            });
                        }
                    });
                }
            }
        }

        $scope.close = function(param) {
            $windowInstance.close(param);
        }
    }
})();
