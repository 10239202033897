(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('SuppliersAdministrationController', suppliersAdministrationController);

    suppliersAdministrationController.$inject = [
        '$scope',
        '$rootScope',
        '$translate',
        '$kWindow',
        'commonUtility',
        'htmlElementService',
        'kendoUtility',
        'adminSupplierService',
        'formTypes',
        'authService',
        'sanitizeService'];

    function suppliersAdministrationController(
        $scope,
        $rootScope,
        $translate,
        $kWindow,
        commonUtility,
        htmlElementService,
        kendoUtility,
        adminSupplierService,
        formTypes,
        authService,
        sanitizeService) {
        $scope.addSupplier = addSupplier;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.suppliers = [];
        $scope.isSuperUser = authService.getAuthData().isSuperuser;

        const suppliersAdministrationGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.suppliers),
                    update: e => supplierGridUpdate(e)
                },
                schema: {
                    model: {
                        id: 'supplierId',
                        fields: {
                            supplierId: { editable: false },
                            name: { editable: true, validation: { required: true } },
                            orgNr: { editable: true, validation: { required: true } },
                            isActive: { editable: true, type: "boolean", },
                            bargeSupplier: { editable: true, type: "boolean", }
                        }
                    },
                }
            }),
            columns: [
                { field: 'supplierId', hidden: true },
                { field: 'name', title: $translate.instant('G_NAME', null, null, langId),
                  template: dataItem => {
                        dataItem.dirty && toggleChangesButtons(false);
                        return dataItem.name ? dataItem.name : '';
                  }
                },
                { field: 'orgNr', title: $translate.instant('ADMIN_CUSTOMERS_ORG_NUMBER', null, null, langId),
                  template: dataItem => {
                        dataItem.dirty && toggleChangesButtons(false);
                        return dataItem.orgNr ? dataItem.orgNr : '';
                  }
                },
                {
                    field: 'bargeSupplier', title: $translate.instant('ADMIN_SUPPLIER_GRID_BARGE_SUPPLIER', null, null, langId),
                    width: 110,
                    template: (dataItem) => {
                        dataItem.dirty && toggleChangesButtons(false);
                        const checked = dataItem.bargeSupplier ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="barge-supplier-checkbox" ${checked} /></div>`;
                    },
                },
                {
                    field: 'mooringSupplier', title: $translate.instant('ADMIN_SUPPLIER_GRID_MOORING_SUPPLIER', null, null, langId),
                    width: 110,
                    template: (dataItem) => {
                        dataItem.dirty && toggleChangesButtons(false);
                        const checked = dataItem.mooringSupplier ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="mooring-supplier-checkbox" ${checked} /></div>`;
                    },
                },
                {
                    field: 'ringSupplier', title: $translate.instant('ADMIN_SUPPLIER_GRID_RING_SUPPLIER', null, null, langId),
                    width: 110,
                    template: (dataItem) => {
                        dataItem.dirty && toggleChangesButtons(false);
                        const checked = dataItem.ringSupplier ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="ring-supplier-checkbox" ${checked} /></div>`;
                    },
                },
                {
                    field: 'netSupplier', title: $translate.instant('ADMIN_SUPPLIER_GRID_NET_SUPPLIER', null, null, langId),
                    width: 110,
                    template: (dataItem) => {
                        dataItem.dirty && toggleChangesButtons(false);
                        const checked = dataItem.netSupplier ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="net-supplier-checkbox" ${checked} /></div>`;
                    },
                },
                {
                    field: 'liceSkirtSupplier', title: $translate.instant('ADMIN_SUPPLIER_GRID_LICESKIRT_SUPPLIER', null, null, langId),
                    width: 110,
                    template: (dataItem) => {
                        dataItem.dirty && toggleChangesButtons(false);
                        const checked = dataItem.liceSkirtSupplier ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="liceskirt-supplier-checkbox" ${checked} /></div>`;
                    },
                },
                { field: 'isActive', title: $translate.instant('G_ACTIVE', null, null, langId), width: 110,
                  template: dataItem => {
                    dataItem.dirty && toggleChangesButtons(false);

                    const checked = dataItem.isActive ? 'checked' : '';
                    return `<div class="center-content"><input type="checkbox" class="status-checkbox" ${checked} /></div>`;
                    }
                },
                {
                    command: [
                        { name: 'updateSupplier', click: e => updateSupplier(e), template: '<a class="k-grid-updateSupplier"><i class="fa fa-edit grid-icon"></i></a>' },
                        { name: 'deleteSupplier', click: e => deleteSupplier(e), template:'<a class="k-grid-deleteSupplier"><i class="fas fa-trash"></i></a>' },
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.suppliersAdministrationGridOptions = suppliersAdministrationGridOptions($translate.use());

        $scope.$on('langChanged', (_, langId) => {
            $scope.suppliersAdministrationGridOptions = suppliersAdministrationGridOptions(langId);
            const suppliersAdministrationGrid = kendoUtility.createWidget('kendoGrid', $scope.suppliersAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(langId, [() => suppliersAdministrationGrid('#suppliers-administration-grid')]);
        });

        initController();

        function initController() {
            if ($scope.isSuperUser) {
                showBusyIndicator('suppliersAdministrationGrid');
                toggleChangesButtons(true);
                addCheckBoxChangeListeners();
                htmlElementService.checkIfElementExists("filter-group").then(() => commonUtility.calculateAdminTabMenuWidth());
                adminSupplierService.getAllSuppliers()
                                    .then(data => $scope.suppliers = data.sort((a, b) => a.name.localeCompare(b.name)))
                                    .catch(error => console.error(error))
                                    .finally(() => {
                                        $scope.suppliersAdministrationGridOptions.dataSource.read();
                                        hideBusyIndicator('suppliersAdministrationGrid');
                                    });
            }
        }

        function toggleChangesButtons(disabled) {
            const saveChangesButton = $('button.k-button.k-grid-save-changes-supplier');
            const cancelChangesButton = $('button.k-button.k-grid-cancel-changes-supplier');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function addCheckBoxChangeListeners() {
            addCheckBoxChangeListener(".status-checkbox", "isActive");
            addCheckBoxChangeListener(".barge-supplier-checkbox", "bargeSupplier");
            addCheckBoxChangeListener(".liceskirt-supplier-checkbox", "liceSkirtSupplier");
            addCheckBoxChangeListener(".net-supplier-checkbox", "netSupplier");
            addCheckBoxChangeListener(".mooring-supplier-checkbox", "mooringSupplier");
            addCheckBoxChangeListener(".ring-supplier-checkbox", "ringSupplier");
        }

        function addCheckBoxChangeListener(className, fieldName) {
            $("#suppliers-administration").on("change", className, function (e) {
                var grid = $("#suppliers-administration-grid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem[fieldName] = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });
        }

        function saveChanges() {
            toggleChangesButtons(true);
            $scope.suppliersAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true);
            $scope.suppliersAdministrationGridOptions.dataSource.read();
        }

        function addSupplier() {
            const addModal = openSupplierModal(formTypes.add);

            addModal.result.then(response => {
                if (response.result) {
                    showBusyIndicator('addSupplier');
                    adminSupplierService.addSupplier(response.result)
                                        .then(result => result && initController())
                                        .finally(() => hideBusyIndicator('addSupplier'));
                }
            })
        }

        function supplierGridUpdate(e) {
            showBusyIndicator('supplierGridUpdate');

            const dataItem = e.data;
            const supplier = {
                name: dataItem.name,
                orgNr: dataItem.orgNr,
                isActive: dataItem.isActive,
                bargeSupplier: dataItem.bargeSupplier,
                netSupplier: dataItem.netSupplier,
                ringSupplier: dataItem.ringSupplier,
                mooringSupplier: dataItem.mooringSupplier,
                liceSkirtSupplier: dataItem.liceSkirtSupplier,
                supplierId: dataItem.supplierId
            };

            adminSupplierService.updateSupplier(supplier)
                .then(response => {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                    } else {
                        e.error();
                    }
                }, error =>e.error(error))
                .finally(() => {
                    hideBusyIndicator('supplierGridUpdate');
                    initController();
                });
        }

        function updateSupplier(event) {
            const supplier = getSupplierFromGrid(event);
            const updateModal = openSupplierModal(formTypes.edit, supplier);

            supplier && updateModal.result.then(response => {
                if (response.result) {
                    showBusyIndicator('updateSupplier');
                    adminSupplierService.updateSupplier(response.result)
                                        .then(result => result && initController())
                                        .finally(() => hideBusyIndicator('updateSupplier'));
                }
            });
        }

        function deleteSupplier(event){
            const supplier = getSupplierFromGrid(event);
            const deleteModal = openDeleteModal();

            supplier && deleteModal.result.then(response => {
                if (response) {
                    showBusyIndicator('deleteSupplier');
                    adminSupplierService.deleteSupplier(supplier.supplierId)
                                        .then(result => result && initController())
                                        .finally(() => hideBusyIndicator('deleteSupplier'));
                }
            });
         }

        function openSupplierModal(formType, supplier = null) {
            const title = formType === formTypes.add ? 'ADMIN_SUPPLIER_GRID_ADD_SUPPLIER' : 'ADMIN_SUPPLIER_GRID_EDIT_SUPPLIER';

            return $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            title: $translate.instant(title),
                            resizable: false,
                            visible: false,
                            width: 550
                        },
                        templateUrl: 'app/shared/popups/supplier-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'SupplierModalController',
                        resolve: {
                            formType: () => formType,
                            supplier: () => supplier
                        }
                   });
        }

        function openDeleteModal() {
            return $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            title: $translate.instant('ADMIN_SUPPLIER_GRID_DELETE_SUPPLIER'),
                            resizable: false,
                            visible: false,
                            width: 300
                        },
                        templateUrl: 'app/shared/popups/confirm-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ConfirmModalController',
                        resolve: {
                            content: function(){
                                return $translate.instant('G_CONFIRM_DELETE', null, null);
                            },
                            primaryBtnText: function () {
                                return null;
                            },
                            secondaryBtnText: function () {
                                return null;
                            }
                        }
                   });
        }

        function getSupplierFromGrid(event) {
            event.preventDefault();

            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');

            return $("#suppliers-administration-grid").data("kendoGrid").dataItem(row).toJSON();
        }

        function showBusyIndicator(id) {
            $rootScope.$broadcast('showBusyIndicator', { id: id, destination: '#suppliers-administration-grid' });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', id);
        }
    }
})();
